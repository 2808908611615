import { BaseDataService } from './Base/BaseDataService';
import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { Constants } from '../Constants';
import { MessageResultDetailsResponse, MessageResultSummaryListResponse } from '../Models/Messages/Message';

const MessageEndpoints = {
    MESSAGES: '/api-admin/v1/sms/bulksends',
    MESSAGE_DETAILS: (id: string) => `/api-admin/v1/sms/bulksends/${id}`,
};

export interface IMessagesDataService {
    getMessages: (
        page?: number,
        count?: number,
        organization_id?: string,
        cts?: XHRRequestCanceler
    ) => Promise<MessageResultSummaryListResponse>;

    getMessageDetails: (
        cts: XHRRequestCanceler,
        messageId: string,
    ) => Promise<MessageResultDetailsResponse>;

    createMessage: (
        message: string,
        workers: Array<string>,
        cts: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<MessageResultDetailsResponse>;
}

class MessagesDataService extends BaseDataService implements IMessagesDataService {
    getMessages = (
        page?: number,
        count?: number,
        organization_id?: string,
        cts?: XHRRequestCanceler
    ): Promise<MessageResultSummaryListResponse> => {
        const params = {
            page: page || 0,
            count: count || Constants.DEFAULT_ITEM_COUNT_PER_PAGE,
            organizationId: organization_id,
        };
        return this.get<MessageResultSummaryListResponse>(MessageEndpoints.MESSAGES, cts, {
            params,
        });
    };

    getMessageDetails = (
        cts: XHRRequestCanceler,
        messageId: string,
    ): Promise<MessageResultDetailsResponse> => {
        return this.get<MessageResultDetailsResponse>(
            MessageEndpoints.MESSAGE_DETAILS(messageId),
            cts,
        );
    };

    createMessage = (
        message: string,
        workers: Array<string>,
        cts: XHRRequestCanceler,
        organization_id?: string
    ): Promise<MessageResultDetailsResponse> => {
        return this.post<MessageResultDetailsResponse>(
            MessageEndpoints.MESSAGES,
            {
                text: message,
                team_user_list: workers,
                organization_id,
            },
            cts,
        );
    };
}
const MessagesDataServiceName = 'MessagesDataService';
export { MessagesDataService, MessagesDataServiceName };
