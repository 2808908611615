import { User } from '../../../Models/Users/Users';

export class UserUtils {
    static getFullName(user?: User): string {
        return user ? `${user.last_name} ${user.first_name}` : '';
    }

    static toFullName = (firstName: string, lastName: string): string => {
        return `${firstName} ${lastName}`;
    };
}
