//🇫🇷
export const fr = {
    ApplicationName: 'TickTime',

    //* COMMONS
    COMMON_YES: 'Oui',
    COMMON_NO: 'Non',
    COMMON_REQUIRED_FIELD: 'Champ obligatoire*',
    COMMON_EMPTY_MESSAGE: 'Aucun résultat',
    COMMON_ADD: 'Ajouter',
    COMMON_CHANGE: 'Modifier',
    COMMON_BAD_VALUE_FIELD: 'Champ erroné',
    COMMON_EXPORT_EXCEL: 'Export XLS',
    COMMON_SEARCH: 'Recherche',
    COMMON_PLACEHOLDER: '-',

    //* ERRORS
    Error_INSUFFICIENT_PERMISSION:
        "Vous n'avez pas les permissions requises pour effectuer cette action",
    Error_GenericError: "Quelque chose ne s'est pas passé comme prévu, merci de réessayer",
    Error_MAIL_ALREADY_EXIST: 'Il y a déjà un utilisateur avec cet email',
    Error_ENTITY_NOT_FOUND: "L'entité n'a pas pu être trouvée",
    Error_PICTURE_NOT_FOUND: "L'image n'a pas pu être trouvée",
    Error_CAN_NOT_EDIT_TODAY_SERVICE:
        "Il n'est pas possible d'éditer une prestation moins de 24h après son démarrage",
    Error_SERVICE_TOO_LONG: 'Une prestation ne peut pas durer plus de 24h',
    Error_StartDateHigherThanEndDate: "L'heure début ne peut pas être après l'heure de fin",
    Error_StartDateHigherThanCurrentDate: "L'heure début ne peut pas être dans le futur",
    Error_EndDateHigherThanCurrentDate: "L'heure fin ne peut pas être dans le futur",
    Error_InvalidDuration: 'Durée invalide',
    Error_PictureUploadFailed: "Erreur lors de l'envoi de la photo",
    Error_AllFieldsRequired: 'Tous les champs sont obligatoires',
    Error_RequiredFieldsCannotBeEmpty: 'Tous les champs obligatoires doivent être saisis',
    Error_ORGANIZATION_DISABLED:
        'Votre compte Ticktime a été désactivé, veuillez contacter TickEdit pour le réactiver',
    Error_ORGANIZATION_NAME_ALREADY_EXIST: 'Nom de l’organisation déjà existant',
    Error_EmailFieldInvalid: 'Email invalide',
    Error_INCORRECT_PHONE_NUMBER_FORMAT: 'Numéro de téléphone invalide',

    //* HEADER
    Header_Dashboard: 'Tableau de bord',
    UtilsInfo_Header: 'Documentation TickTime',
    UtilsCgu_Header: 'CGU',
    UtilsOnBoarding_Header: 'Tutoriel',
    Header_Logout: 'Se déconnecter',

    //* AUTH
    Auth_SubTitle: "Bienvenue sur l'espace",
    Auth_LoginInstructions:
        'Merci de vous connecter avec vos identifiants pour accéder à votre espace personnel.',
    Auth_NewPasswordInstructions: 'Merci de saisir votre nouveau mot de passe.',
    Auth_ForgotInstructions:
        'Merci de renseigner votre adresse mail afin de réinitialiser votre mot de passe.',

    //* LOGIN
    Login_EmailFieldTitle: 'Email*',
    Login_PasswordFieldTitle: 'Mot de passe*',
    Login_ForgotPasswordButtonTitle: 'Mot de passe oublié ?',
    Login_ValidationButtonTitle: 'Se connecter',
    Login_InvalidEmail: 'Email non reconnu',
    Login_InvalidPassword: 'Mot de passe non reconnu',
    Login_InvalidCredentialsMessage:
        'Les identifiants renseignés semblent être erronés. Veuillez réessayer ou réinitialisez votre mot de passe via le lien « Mot de passe oublié ? »',

    //* FORGOT PASSWORD
    ForgotPassword_EmailFieldTitle: 'Email*',
    ForgotPassword_ValidationButtonTitle: 'Envoyer',
    ForgotPassword_RequestSentTitle: 'Demande envoyée',
    ForgotPassword_RequestSentDetails:
        'Votre demande a bien été prise en compte. Si vous faites partie de la base de données, un nouveau mot de passe vous sera envoyé dans les plus brefs délais.',

    //* RESET PASSWORD
    InitPassword_NewPasswordFieldTitle: 'Nouveau mot de passe*',
    InitPassword_ConfirmPasswordFieldTitle: 'Confirmer le nouveau mot de passe*',
    InitPassword_ValidationButtonTitle: 'Valider',
    InitPassword_PasswordMustMatch: 'Les mots de passes doivent être identiques',
    InitPassword_WeakPasswordMessage:
        "Votre mot de passe est trop faible, veillez à ce qu'il soit composé d'au moins 8 caractères, une majuscule, une minuscule et un chiffre.",
    InitPassword_BackToLoginButtonTitle: 'Retour à la connexion',

    //* WORKERS:
    Worker_Common_Label: 'Compagnon',
    Worker_Common_Label_Plural: 'Compagnons',
    WorkerList_Title: '{workers}',
    WorkerCreate_Title: 'AJOUTER UN {worker}',
    WorkerCreate_Toast_Message: '{worker} créé avec succés',
    WorkerInvite_ToastMessage: 'Invitation envoyée avec succés',
    WorkerCodeSend_ToastMessage: 'Code envoyé avec succés',

    //* PRESTATIONS:
    PrestationList_Title: 'PRESTATIONS',
    PrestationCreate_Title: 'CRÉER UNE PRESTATION',
    PrestationCreate_Previous: 'PRESTATIONS',
    PrestationDetails_Title: "DÉTAILS D'UNE PRESTATION",
    PrestationDetails_Previous: 'PRESTATIONS',

    //* SITES:
    SiteList_Title: 'CHANTIERS',
    SiteCreate_Title: 'CRÉER UN CHANTIER',
    SiteCreate_Previous: 'CHANTIERS',
    SiteDetails_Previous: 'CHANTIERS',
    SiteCreate_Toast_Message: 'Chantier créé avec succés',

    //* USERS:
    UserList_Title: 'UTILISATEURS',
    UserList_AddUser: 'Ajouter un utilisateur',
    UserList_Header_TitleName: 'Utilisateurs ',
    UserList_Header_TitleRole: 'Rôles',
    UserCreate_Toast_Message: 'Utilisateur créé avec succés',
    UserInvite_Toast_Message: 'Invitation envoyée avec succés',

    UserCreate_Title: 'NOUVEL UTILISATEUR',
    UserDetails_Previous: 'UTILISATEURS',

    UserInformation_FirstName: 'Prénom*',
    UserInformation_LastName: 'Nom*',
    UserInformation_Email: 'E-mail*',
    UserInformation_Rôle: 'Rôle*',
    UserInformation_Etat: 'État',

    UserInformation_Role_ReadOnly: 'Lecteur',
    UserInformation_Role_User: 'Utilisateur',
    UserInformation_Role_Manager: 'Gestionnaire',
    UserInformation_Role_Admin: 'Administrateur',

    UserInformation_State_Enabled: 'Activé',
    UserInformation_State_Archived: 'Archivé',
    UserInformaion_ButtonInvite_Title: 'Inviter',

    //* TOAST
    Toast_TitleSuccess: 'Succès!',
    Toast_TitleError: 'Erreur!',
    Toast_TitleInfo: 'Info!',

    Toast_GenericSuccessUpdateData: 'Bravo ! Les données ont été mises à jour',

    //* DIALOG
    Dialog_LogoutTitle: 'Déconnexion',
    Dialog_LogoutMessage: 'Êtes-vous sur de vouloir vous déconnecter ?',

    Dialog_SaveTitle: 'Quitter sans enregistrer',
    Dialog_SaveMessage:
        'Des modifications sont en cours, si vous quittez sans enregistrer, elles seront perdues',
    Dialog_SaveConfirm: "Poursuivre l'édition",
    Dialog_SaveCancel: 'Quitter',
    Dialog_DeletePrestationMessage: 'Vous êtes sur le point de supprimer la prestation',
    Dialog_DeletePrestationValidate: 'Confirmer',
    Dialog_DeletePrestationCancel: 'Annuler',
    Dialog_DeleteTitle: 'Supprimer',

    //* COMMON INFO
    CommonInfo_Title: 'Info',
    CommonInfo_SaveButtonTitle: 'Enregistrer',
    CommonInfo_CancelButtonTitle: 'Annuler',
    CommonInfo_DeleteButtonTitle: 'Supprimer',

    //* DATE RANGE FILTER MENU
    DateRangeFilterMenu_StartLabel: 'Du',
    DateRangeFilterMenu_EndLabel: 'Au',

    //* DATE RANGE PICKER
    DateRangePicker_DateFormat: 'dd / MM / yyyy',

    //* USER SITES FILTER
    UserSitesFilter_Title: 'Mes chantiers',

    //* WORKER LIST
    WorkerList_AddWorkerButton: 'Ajouter un {worker}',

    WorkerList_SearchBarPlaceholder: 'Rechercher un {worker}',

    WorkerList_Header_TitleName: '{worker}',
    WorkerList_Header_Productivity: 'Productivité',
    WorkerList_Header_WorkedHoursMonth_Part_1: 'depuis début du',
    WorkerList_Header_WorkedHoursMonth_Part_2: 'mois',
    WorkerList_Header_WorkedHoursMonth: 'depuis début du mois',
    WorkerList_Header_WorkedHoursWeek_Part_1: 'depuis début de',
    WorkerList_Header_WorkedHoursWeek_Part_2: 'la semaine',
    WorkerList_Header_WorkedHoursWeek: 'depuis début de la semaine',
    WorkerList_Header_State: 'État',
    WorkerList_Header_Type: 'Type',
    WorkerList_Header_Phone: 'Téléphone',

    Worker_State_Enabled: 'Actif',
    Worker_State_Disabled: 'Inactif',
    Worker_State_Archived: 'Archivé',

    Worker_Type_Employee: 'Salarié',
    Worker_Type_Interim: 'Intérim',
    Worker_Type_Other: 'Autre',

    //* Worker Information

    WorkerInfo_Photo_Title: 'Photo',
    WorkerInfo_Lastname_Label: 'Nom du {worker}*',
    WorkerInfo_Lastname_Placeholder: 'Saisissez le nom du {worker}',

    WorkerInfo_Name_Label: 'Prénom du {worker}*',
    WorkerInfo_Name_Placeholder: 'Saisissez le prénom du {worker}',

    WorkerInfo_Type_Label: 'Type*',
    WorkerInfo_State_Label: 'État*',

    WorkerInfo_SecurityCode_Label: 'Code sécurité sociale',
    WorkerInfo_SecurityCode_Placeholder: 'Saisissez le code de sécurité sociale',

    WorkerInfo_Phone_Label: 'Téléphone*',
    WorkerInfo_Phone_Placeholder: 'Saisissez le numéro de téléphone',

    WorkerInfo_Invite_Title: 'Envoyer une invitation au {worker}',
    WorkerInfo_SendCode_Title: 'Envoyer un code de connexion au {worker}',

    WorkerInfo_SiteSection_Title: 'Chantiers',

    WorkerInfo_Phone_Invalid: 'Le numéro de téléphone doit contenir 10 chiffres',

    WorkerInfo_Photo_Invalid: 'La photo ne peut pas être trouvée',
    WorkerInfo_Photo_InvalidFormat: "Ce type de fichier n'est pas accepté",

    WorkerInformation_SiteSection_Title: 'Chantiers',

    //* Worker Details
    WorkerDetails_WorkerPrestations: 'Prestations',

    //* Worker Sites
    WorkerSites_All_Title: 'Tous les chantiers',
    WorkerSites_Worker_Title: 'Chantiers actifs de {worker}',
    WorkerSites_NewWorker_Title: 'Chantiers actifs du {worker}',
    WorkerSites_Empty: 'Aucun chantier',
    WorkerSites_Search_Placeholder: 'Rechercher un chantier',

    //* Sites List
    SitesList_AddButton: 'Ajouter un chantier',
    SitesList_SearchBarPlaceholder: 'Rechercher un chantier',

    Site_State_Title: 'État',
    Site_State_Enabled: 'Actif',
    Site_State_Disabled: 'Inactif',
    Site_State_Archived: 'Archivé',

    SiteList_Header_Code: 'Code',
    SiteList_Header_Site: 'Chantier',
    SiteList_Header_Client: 'Client',
    SiteList_Header_SiteSupervisor_1: 'Conducteur',
    SiteList_Header_SiteSupervisor_2: 'de travaux',
    SiteList_Header_TotalHours_1: 'Total heures',
    SiteList_Header_TotalHours_2: 'Productivités',

    SiteList_ProductivityHoursFormat: 'HH:mm:ss',
    SiteList_ProductivityTimeFormat: 'HH [h] mm',

    //* SiteInfo
    SiteInfo_SiteName: 'Nom de chantier*',
    SiteInfo_SiteName_Placeholder: 'Saisissez le nom du chantier',
    SiteInfo_ClientName: 'Nom de client*',
    SiteInfo_Code: 'Code de chantier*',
    SiteInfo_Code_Placeholder: 'Saisissez le code du chantier',
    SiteInfo_Address: 'Adresse',
    SiteInfo_Address_Placeholder: "Saisissez l'adresse du chantier",
    SiteInfo_State_Label: 'État*',
    SiteInfo_EmailIncident: "E-mail (en cas d'incident)*",
    SiteInfo_EmailIncident_Placeholder: 'Saisissez l’email',
    SiteInfo_PhoneIncident: 'Téléphone (en cas d’incident)*',
    SiteInfo_PhoneIncident_Placeholder: 'Saisissez le numéro de téléphone',
    SiteInfo_ConducteurTravaux: 'Nom du conducteur de travaux',
    SiteInfo_ChefEquipe: "Nom du chef d'équipe",

    //* Site Details
    SiteDetails_SitePrestations: 'Prestations',

    //* Prestations List
    PrestationList_SearchBarPlaceholder: 'Rechercher un chantier',
    PrestationList_AddButton: 'Ajouter une prestation',
    PrestationList_AnomalyButton: 'Anomalie',
    PrestationList_ShowAllButton: 'Voir tous',
    PrestationList_Comment_All: 'Tous',
    PrestationList_Comment_WithComment: 'Avec commentaire',
    PrestationList_Comment_WithoutComment: 'Sans commentaire',
    PrestationList_ClockingAnomaly_All: 'Tous',
    PrestationList_ClockingAnomaly_With: 'Avec anomalie',
    PrestationList_ClockingAnomaly_Without: 'Sans anomalie',

    PrestationList_Header_Code: 'Code',
    PrestationList_Header_Site: 'Chantier',
    PrestationList_Header_ClockingAnomaly: 'Anomalie',
    PrestationList_Header_ClockingAnomaly_Hours: 'd\'heures',
    PrestationList_Header_StartDate: 'Date',
    PrestationList_Header_Hour: 'Heure',
    PrestationList_Header_StartHour: 'de début',
    PrestationList_Header_EndHour: 'de fin',
    PrestationList_Header_Gps: 'GPS',
    PrestationList_Header_Worker: '{worker}',
    PrestationList_Header_Comment: 'Commentaire',
    PrestationList_MissingHour: '_',
    PrestationList_DateFormat: 'dd/MM/yyyy',
    PrestationList_HourFormat: "HH'h'mm",
    PrestationList_GpsTooltipEnabled: 'Contient des coordonées Gps',
    PrestationList_GpsTooltipDisabled: 'Coordonées Gps incomplètes ou absentes',
    PrestationList_HourClockedByAnother: '*',
    PrestationList_TotalHours1: 'Total heures: ',
    PrestationList_TotalHours2: '{hours} H',
    PrestationList_TotalHoursMobile: 'TH : ',

    PrestationList_Mobile_Site: 'Nom de chantier',
    PrestationList_Mobile_StartDate: 'Date de début',
    PrestationList_Mobile_StartHour: 'Heure de début',
    PrestationList_Mobile_EndHour: 'Heure de fin',

    //* DatePicker & TimePicker
    DatePicker_DateFormat: 'dd/MM/yyyy',
    TimePicker_TimeFormat: "HH'h'mm",
    TimePicker_DateFormat: 'dd/MM/yyyy',
    DateAndTimePicker_LastEditedString: '* {date}',

    //* PrestationCard
    PrestationCard_DatePickerTitle: 'Date*',
    PrestationCard_TimePickerTitle: 'Heure*',
    PrestationCard_EditedByTitle: 'Pointé par',
    PrestationCard_PhotoTitle: 'Photo',
    PrestationCard_GpsTitle: 'Position',
    PrestationCard_GpsString: 'Lat,Long: {lat},{long}',

    //* PrestationCreate
    PrestationCreate_SiteSearchPlaceholder: 'Rechercher un chantier',
    PrestationCreate_WorkerSearchPlaceholder: 'Rechercher un {worker}',
    PrestationCreate_SiteLabel: 'Chantier*',
    PrestationCreate_WorkerLabel: 'Nom de {worker}*',
    PrestationCreate_ProductivityLabel: 'Heures de productivité',
    PrestationCreate_PrestationCardStartTitle: 'Début',
    PrestationCreate_PrestationCardEndTitle: 'Fin',
    PrestationCreate_CreationSuccess: 'Prestation crée avec succès',
    PrestationCreate_FormatTime: '{hours}h{minutes}',
    PrestationCreate_CannotCreate: "La prestation n'a pas pu être créée",
    PrestationCreate_CannotUpdate: "La prestation n'a pas pu être mise à jour",

    //* PrestationDetails
    PrestationDetails_CannotDelete: "Can't delete prestation",
    PrestationDetails_TeamUser_Comment: 'Commentaire du {worker}',
    PrestationDetails_ClockingAnomaly: 'ANOMALIE D\'HEURES',
    PrestationDetails_ClockingAnomaly_Switch: 'Anomalie',
    PrestationDetails_ClockingAnomaly_Comment: 'Précisez l\'anomalie',
    PrestationDetails_ClockingAnomaly_Comment_Placeholder: 'Votre commentaire ici...',
    PrestationDetails_ClockingAnomaly_Comment_Too_Long: 'Le commentaire est trop long',

    //* PrestationMapHeader
    PrestationMapHeader_Worker: '{worker}',
    PrestationMapHeader_Site: 'Chantier',
    PrestationMapHeader_Date: 'Date',
    PrestationMapHeader_StartHour: 'Début',
    PrestationMapHeader_EndHour: 'Fin',
    PrestationMapHeader_Modif: 'Modifier',

    //* Messages List
    MessagesList_Title: 'MESSAGERIE',
    MessagesList_AddButton: 'Nouveau message',
    MessagesList_Empty: 'Aucun message',
    MessageCreate_Input_Label: 'Message à envoyer par SMS',
    MessageCreate_Input_Placeholder: 'Votre message ici...',
    MessageCreate_Workers_Label: '{workers}',
    MessageCreate_Workers_List: 'Liste des {workers}',
    MessageCreate_Workers_Search_Placeholder: 'Rechercher un {worker}',
    MessageCreate_Workers_Selected: '{workers} sélectionnés',
    MessageCreate_Workers_None: 'Aucun {worker}',
    MessageCreate_Toast_Message: 'Message envoyé avec succès',
    MessageCreate_Toast_Message_Error: '{error_count} destinataires n\'ont pas reçu le message. Vérifiez les numéros.',
    MessageCreate_Workers_Invalid: 'Aucun {worker} sélectionné',
    MessageCreate_Send_Button_Title: 'Envoyer',
    MessageCreate_Cancel_Button_Title: 'Quitter',

    MessageDetails_Title: 'DÉTAILS DU MESSAGE',
    MessageDetails_Input_Label: 'Message envoyé',

    MessagesList_Header_Name: 'Message',
    MessagesList_Header_Date: 'Date',
    MessagesList_Header_Date_Subtitle: 'd\'envoi',
    MessagesList_Header_Recipient_Number: 'Nombre',
    MessagesList_Header_Recipient_Number_Subtitle: 'de destinataires',

    //* CGU
    UtilsCgu_Title: "Conditions Générales d'Utilisation",

    //* HeaderSuperAdmin
    HeaderSuperAdmin_Organization: 'ORGANISATION EN COURS : ',

    //* Dashboard
    Dashboard_Title: 'ORGANISATION',
    Dashboard_TitleSA: 'DASHBOARD TICKEDIT',

    //* OrganizationDetails
    Organization_Dashboard_Right_Col_Title: 'CONTACT:',
    Organization_company_name: 'Nom de la société',
    Organization_team_user_label: 'LIBELLÉ COMPAGNON* (Masculin Singulier)',
    Organization_team_user_plural_label: 'LIBELLÉ COMPAGNONS* (Masculin Pluriel)',
    Organization_kbis: 'N° KBIS',
    Organization_tva: 'N° TVA',
    Organization_address: 'Adresse',
    Organization_organization_phone_number: 'Téléphone',
    Organization_state: 'État*',
    Organization_contact_first_name: 'Nom du contact',
    Organization_contact_last_name: 'Prénom du contact',
    Organization_contact_phone_number: 'Téléphone',
    Organization_contact_email: 'E-mail',
    Organization_State_Enabled: 'Active',
    Organization_State_Disabled: 'Inactive',
    Organization_ShowCalendar:
        'Afficher les totaux des heures pointées dans le calendrier des {workers}*',
    Organization_ShowCalendar_True: 'OUI',
    Organization_ShowCalendar_False: 'NON',
    Organization_HasMessagingAccess: 'Accès à la messagerie',

    //* OrganizationHeaderDashboard
    OrganizationList_Header_Organization: 'Gestion des organisations',
    OrganizationList_Header_StateName: 'ÉTAT',
    OrganizationList_Header_AddButton: 'Ajouter une organisation',
    OrganizationList_State_Enabled: 'Actif',
    OrganizationList_State_Disabled: 'Inactif',

    //* OrganizationCreate
    Dashboard_Create_Title: 'NOUVELLE ORGANISATION',
    OrganizationCreate_Toast_Message: 'Organisation créée avec succès',
    Organization_name: "Nom d'organisation*",
    Organization_admin_last_name: "Nom de l'utilisateur*",
    Organization_admin_first_name: "Prénom de l'utilisateur*",
    Organization_admin_email: 'E-mail*',
    Organization_Create_Save_Button_Title: "Créer l'organisation",

    //* Statistics
    Statistics_Header_Title: 'Statistiques TickTime',
    Statistics_Prompt_teamUsers: 'Utilisateurs uniques',
    Statistics_Prompt_averageTeamUsers: 'Moyenne d’utilisateurs journaliers',
    Statistics_Prompt_maxTeamUsers: 'Record d’utilisateurs journaliers',
    Statistics_Prompt_sumTeamUsers: 'Somme des pointages journaliers',

    //* OnBoarding
    OnBoarding_Welcome_title: 'BIENVENUE',
    OnBoarding_Welcome_text: `Bienvenue sur votre console d’administration Ticktime! 
    
Ce site vous permettra d’administrer les chantiers,
{workers} et prestations de votre organisation.
Avant de commencer, l’équipe Ticktime vous invite 
à consulter et accepter les CGU pour accéder
à la présentation étape par étape du
fonctionnement de la console d’administration.

L’équipe Ticktime`,
    OnBoarding_Welcome_accept: 'J’accepte les CGU de l’application TickTime*',
    OnBoarding_Welcome_accept_error: 'Vous devez accepter les CGU de l’application TickTime*',
    Prompt_Next_title: 'Suivant',
    Prompt_Next_title_last: 'Terminer',
    OnBoarding_Prompt_0_Title: 'Administrateurs',
    OnBoarding_Prompt_0_Description:
        "Bienvenue, votre première étape est de créer les administrateurs et les gestionnaires de l'application Ticktime. ",
    OnBoarding_Prompt_1_Title: 'Administrateurs',
    OnBoarding_Prompt_1_Description:
        'Il vous faut donc créer dans un premier temps les gestionnaires (munissez vous de leur coordonnées et mails).',
    OnBoarding_Prompt_2_Title: 'Administrateurs',
    OnBoarding_Prompt_2_Description: `Ils recevront alors une invitation à finaliser leur inscription en créant leur mot de passe.
    Remarque : pour sécuriser les comptes, le mot de passe doit contenir 8 caractères dont 1 majuscule et 1 chiffre.`,
    OnBoarding_Prompt_3_Title: 'Chantiers',
    OnBoarding_Prompt_3_Description:
        'Ici, les gestionnaires pourront accéder à la liste des chantiers. ',
    OnBoarding_Prompt_4_Title: 'Chantiers',
    OnBoarding_Prompt_4_Description:
        "Il est possible d'exporter la liste de chantiers et les heures pointées sous Excel.",
    OnBoarding_Prompt_5_Title: 'Chantiers',
    OnBoarding_Prompt_5_Description:
        "Pour créer un chantier, nous vous conseillons d'utiliser des noms simples et explicites qui ne portent à confusion. ",
    OnBoarding_Prompt_6_Title: 'Chantiers',
    OnBoarding_Prompt_6_Description:
        "Il vous est possible d'exporter la liste des pointages sur une période de votre chantier sous Excel.",
    OnBoarding_Prompt_7_Title: 'Chantiers',
    OnBoarding_Prompt_7_Description:
        "Il vous est possible d'afficher la liste des pointages sur une période de votre chantier.",
    OnBoarding_Prompt_8_Title: '{workers}',
    OnBoarding_Prompt_8_Description:
        'Sur cet écran, les gestionnaire pourront accéder à la liste des {workers}. ',
    OnBoarding_Prompt_9_Title: '{workers}',
    OnBoarding_Prompt_9_Description:
        "Il est possible d'exporter la liste des {workers} avec leurs coordonnées sous Excel.",
    OnBoarding_Prompt_10_Title: '{workers}',
    OnBoarding_Prompt_10_Description:
        'Pour créer un {worker}, nous vous conseillons de vous munir au minimum de leur numero de téléphone GSM. ',
    OnBoarding_Prompt_11_Title: '{workers}',
    OnBoarding_Prompt_11_Description:
        "Lorsque vous avez saisis toutes ses informations, invitez votre {workers} à télécharger l'application. Il recevra un SMS lui propsant de télécharger l'application sous IOS ou ANDROID",
    OnBoarding_Prompt_12_Title: '{workers}',
    OnBoarding_Prompt_12_Description:
        "Une fois que le {worker} a téléchargé l'application Ticktime, envoyez lui le code d'identification. C'est la passerelle qui assurera le lien entre son application et votre base de données. ",
    OnBoarding_Prompt_13_Title: '{workers}',
    OnBoarding_Prompt_13_Description:
        'Pour éviter à vos {workers} de chercher le chantier sur lequel il doivent pointer, nous vous conseillons de leur attribuer une courte liste. ',
    OnBoarding_Prompt_14_Title: '{workers}',
    OnBoarding_Prompt_14_Description: `Il vous est possible d'afficher la liste des pointages sur une période d'un {worker}. 
    Remarque : le détail des pointages vous donne une aide préciseuse pour analyse les prestations, préparer la paie ou valider des heures supplémentaires.`,
    OnBoarding_Prompt_15_Title: '{workers}',
    OnBoarding_Prompt_15_Description:
        "Il est possible d'exporter la liste des prestations d'un {workers} pour lui envoyer par exemple.",
    OnBoarding_Prompt_16_Title: 'Créer un pointage',
    OnBoarding_Prompt_16_Description:
        'En tant que gestionnaire, vous pouvez créer des pointages à la place de vos {workers} pour parer aux oublis. Cela assurera une exactitude de vos heures de chantier.',
    OnBoarding_Prompt_17_Title: 'Editer un pointage',
    OnBoarding_Prompt_17_Description:
        "Aussi, il vous est possible d'éditer les pointages déjà saisis par vos {workers} en cas d'erreur. ",

    CGU_Title: 'CGU TICKTIME',
    CGU_Content: `Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site, de l’application et des services par le site et l’application TICKTIME et de définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».

    Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
    
    Les CGU doivent être acceptées par tout Utilisateur souhaitant accéder au site. Elles constituent le contrat entre le site et l'Utilisateur. L’accès au site par l’Utilisateur signifie son acceptation des présentes CGU. Il s’engage désormais à respecter les présentes conditions. L’Utilisateur est l’entreprise ayant souscrit le service de pointage à distance, en contrepartie du paiement d’une redevance ouvrant droit d’utilisation conformément aux conditions commerciales prévues par TICKTIME notamment en fonction du nombre de bénéficiaires, et/ou du volume de données. Les bénéficiaires sont les utilisateurs de la solution TICKTIME désignés par l’entreprise Utilisatrice. L’Utilisateur, qui est responsable des données au sens du RGPD, se porte fort de ses bénéficiaires.
    
    Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes.
    
    TICKTIME, pour l’accès aux services proposés par le site se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.
    
    ARTICLE 1 : Mentions légales
    
    L'édition du site TICKTIME est assurée par la Société TickEdit au capital de 30 500 €, immatriculée au RCS de Lille Métropole sous le numéro 478 886 484 dont le siège social est situé au 244 RUE DE L’YSER 59200 TOURCOING. Adresse mail : contact@ticktime.fr
    
    N° de TVA intracommunautaire : FR30478886484
    
    Le Directeur de la publication est Fabien Mijic.
    
    L’hébergeur du site TICKTIME est la Société OVH, au capital de 10 174 560,00 €, immatriculée au RCS de Lille Métropole sous le numéro 424761419, dont le siège social est situé au 2 RUE KELLERMANN 59100 ROUBAIX.
    
    ARTICLE 2 : Accès au site
    
    Le site TICKTIME agit en qualité de tiers de confiance et permet ainsi à l'Utilisateur un accès aux services suivants : - pointage individuel des préposés en extérieur (chantier, réunion extérieur etc…), par système de photographie, d’horodateur et de géolocalisation ;
    
    - reporting mensuel des horaires de travail entrée dans le système de pointage ;
    
    - historique individuel sur une année calendaire ;
    
    Le site en contrepartie du paiement de la redevance convenue, est accessible en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.
    
    L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire en remplissant le formulaire. En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse email et ses coordonnées de GSM.
    
    L’Utilisateur est responsable de la mise à jour des informations fournies. Il lui est précisé qu’il peut les modifier en se connectant à son espace membre.
    
    Pour accéder aux services, l’Utilisateur devra s'identifier à l'aide de son nom d’utilisateur et de son mot de passe qui lui seront communiqués après son inscription et qui sont strictement personnels. A ce titre, il s’en interdit toute divulgation. Dans le cas contraire, il restera seul responsable de l’usage qui en sera fait.
    
    L’Utilisateur pourra également solliciter sa désinscription conformément aux conditions de redevance convenues en se rendant à la page dédiée sur son espace personnel ou envoyant un email à : contact@ticktime.fr. Celle-ci sera effective dans un délai raisonnable.
    
    En cas de non-respect des conditions générales de vente, de paiements et/ou d’utilisation, le site TICKTIME aura la possibilité de suspendre voire de fermer partiellement ou totalement le compte d’un Utilisateur après mise en demeure adressée par voie électronique et restée sans effet.
    
    En cas de dépassement des droits d’utilisation, convenus dans le cadre de la redevance (volume de données, nombre de bénéficiaires etc…), TICKTIME pourra facturer selon ses conditions tarifaires, le dépassement, ou restreindre les conditions d’utilisation pour les mettre en concordance avec la redevance convenue.
    
    Toute suppression de compte, quel qu’en soit le motif, engendre la suppression pure et simple de toutes informations personnelles de l’Utilisateur.
    
    Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilité de (indiquer le nom du site). Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis.
    
    L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email de l’éditeur communiqué à l’ARTICLE 1.
    
    ARTICLE 3 : Données personnelles
    
    Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.
    
    En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, et du RGPD l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit :
    
    par mail à contact@ticktime.fr
    
    via un formulaire de contact ;
    
    via son espace personnel ;
    
    L’Utilisateur demeure responsable du traitement des données collectées auprès de ses préposés dans le strict cadre de l’objectif poursuivi par les services de TICKTIME à savoir la collecte d’informations pour le pointage des horaires de travail des salariés travaillant en extérieur. Les informations recueillis ne peuvent être utilisés à d’autres fins.
    
    La société TICKTIME à mettre en œuvre toutes les mesures de sécurités possibles, et s’engage à préserver l’accès aux données au seul Utilisateur désigné. L’Utilisateur veillera à assurer la confidentialité du mot de passe qui lui sera attribué.
    
    Les données recueillies, traitées et hébergées, seront sauf résiliation conservées pendant une année calendaire.
    
    La société TICKTIME héberge les données au sein de l’Union européenne.
    
    En cas de découverte de faille de sécurité ou de disparition fortuite ou malveillante de donnée, TICKTIME s’engage en alerter l’Utilisateur dans les 72 h de leur découverte et de mettre en œuvre tous les moyens possibles pour y remédier.
    
    TICKTIME ne pourra utiliser les données que pour vérifier la bonne application du contrat et des conditions de redevance, notamment en termes de volumétrie, ou de nombre de bénéficiaires.
    
    TICKTIME en sa qualité de sous-traitante de données s’engage à respecter et à faire respecter par ses préposés et partenaires, la confidentialité des données de l’Utilisateur.
    
    ARTICLE 4 : Propriété intellectuelle
    
    Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.
    
    Toute représentation et/ou reproduction et/ou exploitation partielle ou totale de cette marque, de quelque nature que ce soit, est totalement prohibée.
    
    L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.
    
    Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet ou de l’application constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.
    
    Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.
    
    ARTICLE 5 : Responsabilité
    
    Les sources et informations diffusées sur le site et l’application TICKTIME sont réputées fiables mais le site ne garantit pas qu’ils soient exempts de défauts, d’erreurs ou d’omissions.
    
    Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle.
    
    Malgré des mises à jour régulières, le site TICKTIME ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue sur son site.
    
    L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume les risques liés à l'utilisation de son identifiant et mot de passe. Le site décline toute responsabilité.
    
    Le site TICKTIME ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce site.
    
    La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.
    
    ARTICLE 6 : Liens hypertextes
    
    Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site TICKTIME. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.
    
    ARTICLE 7 : Cookies
    
    L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.
    
    Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site (Indiquer le nom du site). Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.
    
    L’information contenue dans les cookies est utilisée pour améliorer le site, par exemple en :
    
    • permettant à un service de reconnaître l’appareil de l’Utilisateur, pour qu’il n’ait pas à donner les mêmes informations à plusieurs reprises, par exemple remplir un formulaire ou une enquête.
    
    • mémorisant que vous l’Utilisateur a déjà donné ses identifiant et mot de passe, pour ne pas avoir à le refaire à chaque nouvelle page.
    
    • surveillant comment les utilisateurs se servent des services, pour les rendre plus simples d’utilisation et allouer suffisamment de puissance pour s’assurer de leur réactivité.
    
    • analysant des données « anonymisées » pour aider à comprendre comment les utilisateurs interagissent avec les différents aspects des services en ligne et donc permettre de les améliorer. En naviguant sur le site, L’Utilisateur les accepte.
    
    A défaut d’acceptation, l’Utilisateur est informé que certaines fonctionnalités ou pages risquent de lui être refusées.
    
    L’Utilisateur pourra désactiver ce cookie par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.
    
    Eventuellement en cas de publications autorisées par l’Utilisateur
    
    ARTICLE 8 : Publication par l’Utilisateur ou les bénéficiaires
    
    Le site permet aux membres de publier des photographies et des commentaires aux seuls fins de du service de pointage à distance.
    
    Dans ses publications, l’Utilisateur et ses bénéficiaires s’engagent à respecter les règles de la Netiquette (règles de bonne conduite de l’internet) et les règles de droit en vigueur.
    
    L’Utilisateur et ses bénéficiaires reste titulaire de l’intégralité de ses droits de propriété intellectuelle.
    
    Tout contenu mis en ligne par l'Utilisateur et ses bénéficiaires est de sa seule responsabilité.
    
    L'Utilisateur et ses bénéficiaires s'engagent à ne pas mettre en ligne de contenus pouvant porter atteinte aux intérêts de tierces personnes. Tout recours en justice engagé par un tiers lésé contre le site sera pris en charge par l'Utilisateur.
    
    Le contenu de l'Utilisateur peut être à tout moment et pour n'importe quelle raison supprimé ou modifié par le site, sans préavis.
    
    ARTICLE 9 : Droit applicable et juridiction compétente
    
    La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître. Compétence exclusive est donnée dans les litiges avec les sociétés utilisatrices au Tribunal de Commerce de Lille Métropole.
    
    Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.`,
    CGU_Accept: 'Accepter',
    CGU_Refuse: 'Refuser',

    //* INCIDENTS:
    IncidentList_Title: 'INCIDENTS',
    IncidentList_Header_Category: 'Catégorie',
    IncidentList_Header_Client: 'Nom du chantier',
    IncidentList_Header_TeamUser1: '{worker}',
    IncidentList_Header_TeamUser2: 'concerné',
    IncidentList_Header_SentBy: 'Remonté par',
    IncidentList_Header_Date: 'Date',
    IncidentList_Header_IsSolved: 'État',
    IncidentList_DateFormat: 'dd/MM/yyyy',

    //* INCIDENTS DETAILS:
    IncidentDetails_Title: 'DÉTAILS D’UN INCIDENT',
    IncidentDetails_Previous: 'INCIDENTS',
    IncidentDetails_CardStartTitle: 'Info',
    IncidentDetails_Position_Label: 'Position',
    IncidentDetails_Position_LatLong_Label: 'Lat, Long :',
    IncidentDetails_Date_Label: 'Date',
    IncidentDetails_Hour_Label: 'Heure',
    IncidentDetails_Categorie_Label: 'Catégorie',
    IncidentDetails_ConcernedPerson_Label: 'Personne concernée',
    IncidentDetails_DeclaredBy_Label: 'Remonté par',
    IncidentDetails_Photo_Label: 'Photo',
    IncidentDetails_Commentaire_Label: 'Commentaire',
    IncidentDetails_State_Label: 'État',
    IncidentDetails_State_Solved: 'Résolu',
    IncidentDetails_State_NotSolved: 'Non résolu',
};
