import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { BaseApiDataResponse } from '../Models/ApiData';
import { PaginationParams } from '../models/PaginationParams';
import {
    WorkersDetailsResponse,
    WorkersResponse,
    WorkerState,
    WorkerType,
} from '../models/Workers/Workers';
import { BaseDataService } from './Base/BaseDataService';

const WorkersEndpoints = {
    WORKERS: '/api-admin/v1/teamusers',
    WORKERS_CREATE: '/api-admin/v1/teamusers',
    WORKERS_DETAILS: (workerId: string) => `/api-admin/v1/teamusers/${workerId}`,
    WORKER_UPDATE: (workerId: string) => `/api-admin/v1/teamusers/${workerId}`,
    WORKER_INVITE: (workerId: string) => `/api-admin/v1/teamusers/${workerId}/invite`,
    WORKER_PIN: (workerId: string) => `/api-admin/v1/teamusers/${workerId}/pin`,
};

interface WorkersParams extends PaginationParams {
    search?: string;
    state?: WorkerState | string;
    type?: WorkerType | string;
}

export interface IWorkersDataService {
    getWorkers: (
        page?: number,
        count?: number,
        search?: string,
        state?: WorkerState | string,
        type?: WorkerType | string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<WorkersResponse>;

    createWorker: (
        firstName: string,
        lastName: string,
        phoneNumber: string,
        state: WorkerState,
        type: WorkerType,
        activeSitesList: Array<string>,
        pictureId?: string,
        socialSecurityCode?: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<WorkersDetailsResponse>;

    updateWorker: (
        workerId: string,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        state: WorkerState,
        type: WorkerType,
        activeSitesList: Array<string>,
        socialSecurityCode?: string,
        pictureId?: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<WorkersDetailsResponse>;

    getWorkerDetails: (
        workerId: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<WorkersDetailsResponse>;

    inviteWorker: (
        workerId: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<BaseApiDataResponse>;

    pinWorker: (
        workerId: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<BaseApiDataResponse>;
}
class WorkersDataService extends BaseDataService implements IWorkersDataService {
    getWorkers = (
        page?: number,
        count?: number,
        search?: string,
        state?: WorkerState | string,
        type?: WorkerType | string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ): Promise<WorkersResponse> => {
        const params = { page, count, search, state, type, organizationId: organization_id };
        return this.get<WorkersResponse>(WorkersEndpoints.WORKERS, cts, { params });
    };

    createWorker = (
        firstName: string,
        lastName: string,
        phoneNumber: string,
        state: WorkerState,
        type: WorkerType,
        activeSitesList: Array<string>,
        pictureId?: string,
        socialSecurityCode?: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ): Promise<WorkersDetailsResponse> => {
        const body = {
            first_name: firstName,
            last_name: lastName,
            picture_id: pictureId,
            phone_number: phoneNumber,
            social_security_code: socialSecurityCode,
            state: state,
            type: type,
            active_site_list: activeSitesList,
            organization_id: organization_id,
        };
        return this.post<WorkersDetailsResponse>(WorkersEndpoints.WORKERS_CREATE, body, cts);
    };

    updateWorker = (
        workerId: string,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        state: WorkerState,
        type: WorkerType,
        activeSitesList: Array<string>,
        socialSecurityCode?: string,
        pictureId?: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ): Promise<WorkersDetailsResponse> => {
        const body = {
            first_name: firstName,
            last_name: lastName,
            picture_id: pictureId,
            phone_number: phoneNumber,
            social_security_code: socialSecurityCode,
            state: state,
            type: type,
            active_site_list: activeSitesList,
            organization_id: organization_id,
        };
        return this.put<WorkersDetailsResponse>(
            WorkersEndpoints.WORKER_UPDATE(workerId),
            body,
            cts
        );
    };

    getWorkerDetails = (
        workerId: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ): Promise<WorkersDetailsResponse> => {
        const params = {
            organizationId: organization_id,
        };
        return this.get<WorkersDetailsResponse>(WorkersEndpoints.WORKERS_DETAILS(workerId), cts, {
            params,
        });
    };

    inviteWorker = (workerId: string, cts?: XHRRequestCanceler, organization_id?: string) => {
        const params = {
            organizationId: organization_id,
        };
        return this.post<BaseApiDataResponse>(WorkersEndpoints.WORKER_INVITE(workerId), {}, cts, {
            params,
        });
    };

    pinWorker = (workerId: string, cts?: XHRRequestCanceler, organization_id?: string) => {
        const params = {
            organizationId: organization_id,
        };
        return this.post<BaseApiDataResponse>(WorkersEndpoints.WORKER_PIN(workerId), {}, cts, {
            params,
        });
    };
}

const WorkersDataServiceName = 'WorkersDataService';
export { WorkersDataService, WorkersDataServiceName };
