import moment from 'moment';

export interface ExportProps {
    url: string;
    auth: string;
    startDate?: Date;
    endDate?: Date;
    workerId?: string;
    siteId?: string;
    organization_id?: string;
}

export class ExportUtils {
    static getExportUrlWithParams = ({
        url,
        auth,
        startDate,
        endDate,
        workerId,
        siteId,
        organization_id,
    }: ExportProps): string => {
        let resultString = `${url}?Authorization=${auth}`;
        if (startDate) {
            resultString += `&startDate=${encodeURIComponent(moment(startDate).toISOString(true))}`;
        }
        if (endDate) {
            resultString += `&endDate=${encodeURIComponent(moment(endDate).toISOString(true))}`;
        }
        if (workerId) {
            resultString += `&userId=${workerId}`;
        }
        if (siteId) {
            resultString += `&siteId=${siteId}`;
        }
        if (organization_id) {
            resultString += `&organizationId=${organization_id}`;
        }
        return resultString;
    };

    static downloadFileFromUrl = (url: string) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '');
        link.click();
    };
}
