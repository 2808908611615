import { MessageResultSummary } from '../../../Models/Messages/Message';
import {
    MessagesActionType, SetMessagesAction, SetMessagesInitializedAction, SetMessagesPageAction,
} from './typings/MessagesActionTypes';

export class MessagesActions {
    static setMessagesInitialized = (initialized: boolean): SetMessagesInitializedAction => ({
        type: MessagesActionType.SET_MESSAGES_INITIALIZED,
        payload: { initialized },
    });

    static setMessages = (items: Array<MessageResultSummary>): SetMessagesAction => ({
        type: MessagesActionType.SET_MESSAGES,
        payload: { items },
    });

    static setMessagesPage = (page: number): SetMessagesPageAction => ({
        type: MessagesActionType.SET_PAGE,
        payload: { page },
    });
}
