import { MessagesAction, MessagesActionType } from './typings/MessagesActionTypes';
import { MessagesState } from './typings/MessagesState';

const initialState: MessagesState = {
    items: [],
    initialized: false,
    page: 0,
};

const MessagesReducer = (state = initialState, action: MessagesAction): MessagesState => {
    switch (action.type) {
        case MessagesActionType.SET_MESSAGES_INITIALIZED:
            return { ...state, initialized: action.payload.initialized };
        case MessagesActionType.SET_MESSAGES:
            return { ...state, items: action.payload.items };
        case MessagesActionType.SET_PAGE:
            return { ...state, page: action.payload.page };
        default:
            return state;
    }
};

export { MessagesReducer };
