import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../../Components/Templates/AppRouter/PrivateRoute';
import { LoadableUtilsCgu } from '../../Pages/Utils/UtilsCgu/LoadableUtilsCgu';
import { AppRoutes } from '../../../Core/Routes/Routes';

const getUtilsCguRoute = (props: object): AppRouteType => ({
    id: AppRoutes.CGU,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.CGU,
        component: LoadableUtilsCgu,
        ...props,
    },
});
export const getUtilsRoutes = (args: object) => [
    getUtilsCguRoute(args),
];
