import { IncidentsAction, IncidentsActionType } from './typings/IncidentsActionTypes';
import { IncidentsState } from './typings/IncidentsState';

const initialState: IncidentsState = {
    items: [],
    initialized: false,
    page: 0,
};

const IncidentsReducer = (state = initialState, action: IncidentsAction): IncidentsState => {
    switch (action.type) {
        case IncidentsActionType.SET_INCIDENTS_INITIALIZED:
            return { ...state, initialized: action.payload.initialized };
        case IncidentsActionType.SET_INCIDENTS:
            return { ...state, items: action.payload.items };
        case IncidentsActionType.SET_PAGE:
            return { ...state, page: action.payload.page };
        default:
            return state;
    }
};

export { IncidentsReducer };
