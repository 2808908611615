import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import { Constants } from '../../Core/Constants';
import { RouterMiddleware } from '../history/history';
import { ApplicationReducer } from './AppReducer';
import { InitialState } from './InitialState';
import { AuthenticationMiddleware } from '../../Core/Middlewares/AuthenticationMiddleware';
import { MeMiddleware } from '../../Core/Middlewares/MeMiddleware';
import { BackOfficeUsersMiddleware } from '../../Core/Middlewares/BackOfficeUsersMiddleware';
import { WorkersUsersMiddleware } from '../../Core/Middlewares/WorkersMiddleware';
import { SitesMiddleware } from '../../Core/Middlewares/SitesMiddleware';

const composeEnhancers = composeWithDevTools({ name: 'rudant-backoffice' });

const middlewares = [
    thunk,
    RouterMiddleware,
    AuthenticationMiddleware,
    MeMiddleware,
    BackOfficeUsersMiddleware,
    WorkersUsersMiddleware,
    SitesMiddleware,
];

if (Constants.isDev) {
    const { createLogger } = require(`redux-logger`);
    const logger = createLogger({
        predicate: (getState, action) => !action.type.includes('@@router'),
    });
    middlewares.push(logger);
}

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(ApplicationReducer, InitialState, enhancer);

const persistor = persistStore(store);

export { store, persistor };
