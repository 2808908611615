import {
    PrestationsResponse,
    PrestationDetailsResponse,
} from '../../../models/Prestations/Prestations';
import {
    IPrestationsDataService,
    PrestationsDataServiceName,
} from '../../../DataServices/PrestationDataService';
import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import { PrestationsActions } from './PrestationsActions';
import { IBaseReduxService, BaseReduxService } from '../Api/BaseReduxService';
import { IoC } from '../../ServicesContainer';
import { ExportUtils } from '../../../../Core/Utils/ExportUtils';
import { Constants } from '../../../Constants';

export interface DownloadPhotoProps {
    photoUrl: string;
    photoName: string;
}

export enum PrestationCommentTypes {
    All = 'PrestationList_Comment_All',
    WithComment = 'PrestationList_Comment_WithComment',
    WithoutComment = 'PrestationList_Comment_WithoutComment',
}

export enum PrestationClockingAnomalyTypes {
    All = 'PrestationList_ClockingAnomaly_All',
    WithClockingAnomaly = 'PrestationList_ClockingAnomaly_With',
    WithoutClockingAnomaly = 'PrestationList_ClockingAnomaly_Without',
}

export interface IPrestationsService extends IBaseReduxService {
    getPrestations: (
        cts: XHRRequestCanceler,
        startDate: Date,
        endDate: Date,
        search: string,
        anomaly: boolean | null,
        filter_current_user_sites: boolean,
        page: number,
        count: number,
        siteId: string | undefined,
        workerId: string | undefined,
        organization_id?: string,
        comment?: boolean,
        is_clocking_anomaly?: boolean
    ) => Promise<PrestationsResponse>;
    createPrestation: (
        cts: XHRRequestCanceler,
        siteId: string,
        workerId: string,
        startDate: string,
        endDate: string,
        organization_id?: string,
        clocking_anomaly_comment?: string,
        is_clocking_anomaly?: boolean
    ) => Promise<PrestationDetailsResponse>;
    getPrestationDetails: (
        cts: XHRRequestCanceler,
        prestationId: string,
        organization_id?: string
    ) => Promise<PrestationDetailsResponse>;
    deletePrestation: (
        cts: XHRRequestCanceler,
        prestationId: string,
        organization_id?: string
    ) => Promise<boolean>;
    updatePrestation: (
        cts: XHRRequestCanceler,
        prestationId: string,
        siteId: string,
        workerId: string,
        startDate: string,
        startEditedBy: string,
        endDate: string,
        endEditedBy: string,
        organization_id?: string,
        clocking_anomaly_comment?: string,
        is_clocking_anomaly?: boolean
    ) => Promise<PrestationDetailsResponse>;
    setPage: (page: number) => void;
    setStartDate: (startDate: Date) => void;
    setEndDate: (endDate: Date) => void;
    setSearch: (search: string) => void;
    setAnomaly: (anomaly: boolean) => void;
    setClockingAnomaly: (clockingAnomaly?: boolean) => void;
    setFilterCurrentUserSites: (userSites: boolean) => void;
    setComment: (comment?: boolean) => void;
    downloadPhoto: ({ photoUrl, photoName }: DownloadPhotoProps) => void;
    exportXLS: (
        startDate: Date,
        endDate: Date,
        auth: string,
        siteId?: string,
        workerId?: string,
        organization_id?: string
    ) => void;
}

class PrestationsService extends BaseReduxService implements IPrestationsService {
    private readonly prestationsDataService: IPrestationsDataService;

    constructor() {
        super();
        this.prestationsDataService = IoC.get<IPrestationsDataService>(PrestationsDataServiceName);
    }

    public getPrestations = async (
        cts: XHRRequestCanceler,
        startDate: Date,
        endDate: Date,
        search: string,
        anomaly: boolean | null,
        filter_current_user_sites: boolean,
        page: number,
        count: number,
        siteId: string | undefined,
        workerId: string | undefined,
        organization_id?: string,
        comment?: boolean,
        is_clocking_anomaly?: boolean
    ) => {
        try {
            const response: PrestationsResponse = await this.prestationsDataService.getPrestations(
                cts,
                startDate,
                endDate,
                search,
                anomaly,
                filter_current_user_sites,
                page,
                count,
                siteId,
                workerId,
                organization_id,
                comment,
                is_clocking_anomaly
            );
            this.dispatch(PrestationsActions.SetPrestationsInitialized(true));
            this.dispatch(PrestationsActions.SetPrestationsList(response.data));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public createPrestation = async (
        cts: XHRRequestCanceler,
        siteId: string,
        workerId: string,
        startDate: string,
        endDate: string,
        organization_id?: string,
        clocking_anomaly_comment?: string,
        is_clocking_anomaly?: boolean
    ) => {
        try {
            const response = await this.prestationsDataService.createPrestation(
                cts,
                siteId,
                workerId,
                startDate,
                endDate,
                organization_id,
                clocking_anomaly_comment,
                is_clocking_anomaly
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public getPrestationDetails = async (
        cts: XHRRequestCanceler,
        prestationId: string,
        organization_id?: string
    ) => {
        try {
            const response = await this.prestationsDataService.getPrestationDetails(
                cts,
                prestationId,
                organization_id
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public deletePrestation = async (
        cts: XHRRequestCanceler,
        prestationId: string,
        organization_id?: string
    ): Promise<boolean> => {
        try {
            await this.prestationsDataService.deletePrestation(cts, prestationId, organization_id);
            return Promise.resolve(true);
        } catch {
            return Promise.reject(false);
        }
    };

    public updatePrestation = async (
        cts: XHRRequestCanceler,
        prestationId: string,
        siteId: string,
        workerId: string,
        startDate: string,
        startEditedBy: string,
        endDate: string,
        endEditedBy: string,
        organization_id?: string,
        clocking_anomaly_comment?: string,
        is_clocking_anomaly?: boolean
    ) => {
        try {
            const response = await this.prestationsDataService.updatePrestation(
                cts,
                prestationId,
                siteId,
                workerId,
                startDate,
                startEditedBy,
                endDate,
                endEditedBy,
                organization_id,
                clocking_anomaly_comment,
                is_clocking_anomaly
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public setPage = async (page: number) => {
        this.dispatch(PrestationsActions.SetPrestationPage(page));
    };

    public setStartDate = async (startDate: Date) => {
        this.dispatch(PrestationsActions.SetPrestationStartDate(startDate));
    };

    public setEndDate = async (endDate: Date) => {
        this.dispatch(PrestationsActions.SetPrestationEndDate(endDate));
    };

    public setSearch = async (search: string) => {
        this.dispatch(PrestationsActions.SetPrestationSearch(search));
    };

    public setAnomaly = async (anomaly: boolean) => {
        this.dispatch(PrestationsActions.SetPrestationAnomaly(anomaly));
    };

    public setFilterCurrentUserSites = async (userSites: boolean) => {
        this.dispatch(PrestationsActions.SetPrestationFilterCurrentUserSites(userSites));
    };

    public setComment = async (comment?: boolean) => {
        this.dispatch(PrestationsActions.SetPrestationComment(comment));
    };

    public setClockingAnomaly = async (clockingAnomaly?: boolean) => {
        this.dispatch(PrestationsActions.SetPrestationClockingAnomaly(clockingAnomaly));
    };

    public downloadPhoto = async ({ photoUrl, photoName }: DownloadPhotoProps) => {
        try {
            const response = await this.prestationsDataService.downloadPhoto(photoUrl);
            const buffer = await response.arrayBuffer();
            const link = document.createElement('a');
            const file = new Blob([buffer]);
            link.href = URL.createObjectURL(file);
            link.setAttribute('download', photoName);
            link.click();
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public exportXLS = async (
        startDate: Date,
        endDate: Date,
        auth: string,
        siteId?: string,
        workerId?: string,
        organization_id?: string
    ) => {
        ExportUtils.downloadFileFromUrl(
            ExportUtils.getExportUrlWithParams({
                url: Constants.getRoot() + '/api-admin/v1/services/export',
                auth: auth,
                startDate: startDate,
                endDate: endDate,
                siteId: siteId,
                workerId: workerId,
                organization_id: organization_id,
            })
        );
    };
}

const PrestationsServiceName = 'PrestationsService';
export { PrestationsService, PrestationsServiceName };
