import { ToastId } from 'react-toastify';
import { IoC } from '../ServicesContainer';

export interface IToastService {
    showSuccess(content: string): ToastId;

    showError(content: string): ToastId;

    showInfo(content: string): ToastId;

    showSuccessUpdate(): ToastId

    notImplemented(): ToastId;
}

const ToastServiceName = 'ToastService';
const getToastService = () => IoC.get<IToastService>(ToastServiceName);

export { ToastServiceName, getToastService }