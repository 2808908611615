import DateFnsUtils from '@date-io/date-fns';
import { fr as frLocale } from 'date-fns/esm/locale/';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React from 'react';

const DatePickerUtilsProvider = ({ children }) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
        {children}
    </MuiPickersUtilsProvider>
);

DatePickerUtilsProvider.propTypes = { children: PropTypes.node };

DatePickerUtilsProvider.defaultProps = {
    children: null,
};

export { DatePickerUtilsProvider };
