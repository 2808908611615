import './customToastContainer.scss';

import * as React from 'react';
import { ToastContainer } from 'react-toastify';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CustomToastContainerProps {}

const CustomToastContainer: React.FunctionComponent<CustomToastContainerProps> = () => {
    return (
        <ToastContainer
            autoClose={8000}
            position="top-right"
            hideProgressBar
            closeOnClick={false}
            className="toast-container-root"
            toastClassName="toast-simple"
            bodyClassName="toast-body"
            closeButton={false}
        />
    );
};

export { CustomToastContainer };
