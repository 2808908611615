import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../../Components/Templates/AppRouter/PrivateRoute';
import { AppRoutes } from '../../../Core/Routes/Routes';
import { LoadableIncidentList } from '../../Pages/Incidents/IncidentList/LoadableIncidentList';
import { LoadableIncidentDetails } from '../../Pages/Incidents/IncidentDetails/LoadableIncidentDetails';

const getIncidentListRoute = (props: object): AppRouteType => ({
    id: AppRoutes.INCIDENT_LIST,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.INCIDENT_LIST,
        component: LoadableIncidentList,
        ...props,
    },
});

const getIncidentDetailsRoute = (props: object): AppRouteType => ({
    id: AppRoutes.INCIDENT_DETAILS,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.INCIDENT_DETAILS,
        component: LoadableIncidentDetails,
        ...props,
    },
});

export const getIncidentRoutes = (args: object) => [
    getIncidentListRoute(args),
    getIncidentDetailsRoute(args),
];
