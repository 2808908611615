import { Ability } from '@casl/ability';

import * as MeActionTypes from './typings/MeActionTypes';
import { UserInfo } from '../../../../Models/Users/Users';

export class MeActions {
    static setInitialized = (initialized: boolean): MeActionTypes.SetInitializedAction => ({
        type: MeActionTypes.MeActionType.SET_INITIALIZED,
        payload: { initialized },
    });

    static setInfo = (info?: UserInfo): MeActionTypes.SetInfoAction => ({
        type: MeActionTypes.MeActionType.SET_INFO,
        payload: { info },
    });

    static setAbility = (
        ability: Ability,
        updaterName: string
    ): MeActionTypes.SetAbilityAction => ({
        type: MeActionTypes.MeActionType.SET_ABILITY,
        payload: { ability, updaterName },
    });

    static setAcceptCgu = (cgu: boolean): MeActionTypes.SetAcceptCguAction => ({
        type: MeActionTypes.MeActionType.SET_ACCEPT_CGU,
        payload: { cgu },
    });

    static setSuperAdminTeamUserLabels = (
        teamUserLabel: string,
        teamUserPluralLabel: string,
    ): MeActionTypes.SetSuperAdminTeamUserLabelsAction => ({
        type: MeActionTypes.MeActionType.SET_SUPER_ADMIN_TEAM_USER_LABELS,
        payload: { teamUserLabel, teamUserPluralLabel },
    });
}
