import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../../Components/Templates/AppRouter/PrivateRoute';
import { AppRoutes } from '../../../Core/Routes/Routes';
import { LoadableMessageList } from '../../Pages/Messages/MessageList/LoadableMessageList';
import { LoadableMessageCreate } from '../../Pages/Messages/MessageCreate/LoadableMessageCreate';
import { LoadableMessageDetails } from '../../Pages/Messages/MessageDetails/LoadableMessageDetails';

const getMessageListRoute = (props: object): AppRouteType => ({
    id: AppRoutes.MESSAGE_LIST,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.MESSAGE_LIST,
        component: LoadableMessageList,
        ...props,
    },
});

const getMessageCreateRoute = (props: object): AppRouteType => ({
    id: AppRoutes.MESSAGE_CREATE,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.MESSAGE_CREATE,
        component: LoadableMessageCreate,
        ...props,
    },
});

const getMessageDetailsRoute = (props: object): AppRouteType => ({
    id: AppRoutes.MESSAGE_DETAILS,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.MESSAGE_DETAILS,
        component: LoadableMessageDetails,
        ...props,
    },
});

export const getMessageRoutes = (args: object) => [
    getMessageListRoute(args),
    getMessageCreateRoute(args),
    getMessageDetailsRoute(args),
];
