import { Route } from 'react-router-dom';

import { LoadableLogin } from '../../Pages/Auth/Login/LoadableLogin';
import { LoadableForgotPassword } from '../../Pages/Auth/ForgotPassword/LoadableForgotPassword';
import { LoadableResetPassword } from '../../Pages/Auth/PasswordReset/LoadableResetPassword';
import { LoadableCreatePassword } from '../../Pages/Auth/CreatePassword/LoadableCreatePassword';
import { AppRoutes } from '../../../Core/Routes/Routes';

import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';

export const getLoginRoute = (props: object): AppRouteType => ({
    id: AppRoutes.LOGIN,
    render: Route,
    props: {
        exact: true,
        path: AppRoutes.LOGIN,
        component: LoadableLogin,
        ...props,
    },
});

export const getCreatePasswordRoute = (props: object): AppRouteType => ({
    id: AppRoutes.PASSWORD_CREATE,
    render: Route,
    props: {
        exact: true,
        path: AppRoutes.PASSWORD_CREATE,
        component: LoadableCreatePassword,
        ...props,
    },
});

export const getForgotPasswordRoute = (props: object): AppRouteType => ({
    id: AppRoutes.PASSWORD_FORGOT,
    render: Route,
    props: {
        exact: true,
        path: AppRoutes.PASSWORD_FORGOT,
        component: LoadableForgotPassword,
        ...props,
    },
});

export const getResetPasswordRoute = (props: object): AppRouteType => ({
    id: AppRoutes.PASSWORD_RESET,
    render: Route,
    props: {
        exact: true,
        path: AppRoutes.PASSWORD_RESET,
        component: LoadableResetPassword,
        ...props,
    },
});

export const getAuthRoutes = (args: object) => [
    getLoginRoute(args),
    getCreatePasswordRoute(args),
    getForgotPasswordRoute(args),
    getResetPasswordRoute(args),
];
