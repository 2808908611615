import * as PrestationsActionsTypes from './typings/PrestationsActionsTypes';
import { PrestationsState } from './typings/PrestationsState';

const initialState: PrestationsState = {
    prestations: [],
    filters: { anomaly: false, endDate: new Date(), startDate: new Date(), search: '', filter_current_user_sites: false, comment: undefined },
    initialized: false,
    page: 0,
};

const PrestationsReducer = (
    state = initialState,
    action: PrestationsActionsTypes.PrestationsActions
): PrestationsState => {
    switch (action.type) {
        case PrestationsActionsTypes.PrestationsActionsTypes.SET_PRESTATIONS_LIST:
            return { ...state, prestations: action.payload.prestations };
        case PrestationsActionsTypes.PrestationsActionsTypes.SET_PRESTATIONS_INITIALIZED:
            return { ...state, initialized: action.payload.initialized };
        case PrestationsActionsTypes.PrestationsActionsTypes.SET_PRESTATIONS_ANOMALY:
            return { ...state, filters: { ...state.filters, anomaly: action.payload.anomaly } };
        case PrestationsActionsTypes.PrestationsActionsTypes.SET_PRESTATIONS_START_DATE:
            return { ...state, filters: { ...state.filters, startDate: action.payload.startDate } };
        case PrestationsActionsTypes.PrestationsActionsTypes.SET_PRESTATIONS_END_DATE:
            return { ...state, filters: { ...state.filters, endDate: action.payload.endDate } };
        case PrestationsActionsTypes.PrestationsActionsTypes.SET_PRESTATIONS_SEARCH:
            return { ...state, filters: { ...state.filters, search: action.payload.search } };
        case PrestationsActionsTypes.PrestationsActionsTypes.SET_PRESTATIONS_FILTER_CURRENT_USER_SITES:
            return { ...state, filters: { ...state.filters, filter_current_user_sites: action.payload.filter_current_user_sites } };
        case PrestationsActionsTypes.PrestationsActionsTypes.SET_PRESTATIONS_COMMENT:
            return { ...state, filters: { ...state.filters, comment: action.payload.comment } };
        case PrestationsActionsTypes.PrestationsActionsTypes.SET_PRESTATIONS_PAGE:
            return { ...state, page: action.payload.page };
        default:
            return state;
    }
};

export { PrestationsReducer };
