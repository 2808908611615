import { IncidentResultSummary } from '../../../../Models/Incidents/Incident';

export enum IncidentsActionType {
    SET_INCIDENTS_INITIALIZED = 'app/incidents/set/initialized',
    SET_INCIDENTS = 'app/incidents/set/incidents',
    SET_FILTER_CURRENT_USER_SITES = 'app/incidents/set/filter_current_user_sites',
    SET_PAGE = 'app/incidents/set/page',
}

export interface SetIncidentsInitializedAction {
    type: typeof IncidentsActionType.SET_INCIDENTS_INITIALIZED;
    payload: { initialized: boolean };
}

export interface SetIncidentsAction {
    type: typeof IncidentsActionType.SET_INCIDENTS;
    payload: { items: Array<IncidentResultSummary> };
}

export interface SetIncidentsFilterCurrentUserSitesAction {
    type: typeof IncidentsActionType.SET_FILTER_CURRENT_USER_SITES;
    payload: { filter_current_user_sites: boolean };
}

export interface SetIncidentsPageAction {
    type: typeof IncidentsActionType.SET_PAGE;
    payload: { page: number };
}

export type IncidentsAction =
    | SetIncidentsInitializedAction
    | SetIncidentsAction
    | SetIncidentsFilterCurrentUserSitesAction
    | SetIncidentsPageAction;
