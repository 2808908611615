import { MessageResultSummary } from "../../../../Models/Messages/Message";

export enum MessagesActionType {
    SET_MESSAGES_INITIALIZED = 'app/messages/set/initialized',
    SET_MESSAGES = 'app/messages/set/messages',
    SET_PAGE = 'app/messages/set/page',
}

export interface SetMessagesInitializedAction {
    type: typeof MessagesActionType.SET_MESSAGES_INITIALIZED;
    payload: { initialized: boolean };
}

export interface SetMessagesAction {
    type: typeof MessagesActionType.SET_MESSAGES;
    payload: { items: Array<MessageResultSummary> };
}

export interface SetMessagesPageAction {
    type: typeof MessagesActionType.SET_PAGE;
    payload: { page: number };
}

export type MessagesAction =
    | SetMessagesInitializedAction
    | SetMessagesAction
    | SetMessagesPageAction;
