import { useSelector } from 'react-redux';
import { AppState } from '../../../../Configs/store/AppReducer';
import { BackOfficeUserRole } from '../../../../Core/Models/Users/Users';


export interface UsePrivateRouteAccessApi {
    isAuthenticated: boolean;
    loading: boolean;
    has_messaging_access: boolean;
}

export const usePrivateRouteAccess = (): UsePrivateRouteAccessApi =>
    useSelector<AppState, UsePrivateRouteAccessApi>(
        (state): UsePrivateRouteAccessApi => {
            return {
                isAuthenticated: state.Authentication.isAuthenticated,
                loading: !state.Authentication.initialized || !state.Me.initialized,
                has_messaging_access: state.Me.data?.has_messaging_access || state.Me.data?.role === BackOfficeUserRole.SuperAdmin,
            };
        }
    );
