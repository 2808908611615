import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../../Components/Templates/AppRouter/PrivateRoute';
import { LoadableUserCreate } from '../../Pages/Users/UserCreate/LoadableUserCreate';
import { LoadableUserList } from '../../Pages/Users/UserList/LoadableUserList';
import { LoadableUserDetails } from '../../Pages/Users/UserDetails/LoadableUserDetails';
import { AppRoutes } from '../../../Core/Routes/Routes';

const getUserListRoute = (props: object): AppRouteType => ({
    id: AppRoutes.USER_LIST,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.USER_LIST,
        component: LoadableUserList,
        ...props,
    },
});
const getUserCreateRoute = (props: object): AppRouteType => ({
    id: AppRoutes.USER_CREATE,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.USER_CREATE,
        component: LoadableUserCreate,
        ...props,
    },
});
const getUserDetailsRoute = (props: object): AppRouteType => ({
    id: AppRoutes.USER_DETAILS,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.USER_DETAILS,
        component: LoadableUserDetails,
        ...props,
    },
});
export const getUserRoutes = (args: object) => [
    getUserListRoute(args),
    getUserCreateRoute(args),
    getUserDetailsRoute(args),
];
