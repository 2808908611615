import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import {
    ClientsResponse,
    SiteDetailsResponse,
    SitesResponse,
    SiteState,
} from '../models/Sites/Sites';
import { BaseDataService } from './Base/BaseDataService';

const SitesEndpoints = {
    SITES: '/api-admin/v1/sites',
    SITE_DETAILS: (id: string) => `/api-admin/v1/sites/${id}`,
    CLIENTS: '/api-admin/v1/clients',
    CREATE_SITE: '/api-admin/v1/sites',
    UPDATE_SITE: (id: string) => `/api-admin/v1/sites/${id}`,
};

export interface ISitesDataService {
    getSites: (
        cts: XHRRequestCanceler,
        page?: number,
        count?: number,
        filter_current_user_sites?: boolean,
        search?: string,
        siteState?: SiteState | string,
        organization_id?: string
    ) => Promise<SitesResponse>;

    getSiteDetails: (
        cts: XHRRequestCanceler,
        id: string,
        organization_id?: string
    ) => Promise<SiteDetailsResponse>;

    createSite: (
        cts: XHRRequestCanceler,
        name: string,
        state: SiteState,
        client: string,
        code: string,
        address: string,
        email_incident: string,
        phone_incident: string,
        organization_id?: string,
        conducteur_travaux_id?: string,
        chef_equipe_id?: string
    ) => Promise<SiteDetailsResponse>;

    updateSite: (
        id: string,
        cts: XHRRequestCanceler,
        name: string,
        state: SiteState,
        client: string,
        code: string,
        address: string,
        email_incident: string,
        phone_incident: string,
        organization_id?: string,
        conducteur_travaux_id?: string,
        chef_equipe_id?: string
    ) => Promise<SiteDetailsResponse>;

    getClients: (cts: XHRRequestCanceler, organization_id?: string) => Promise<ClientsResponse>;
}

class SitesDataService extends BaseDataService implements ISitesDataService {
    getSites = (
        cts: XHRRequestCanceler,
        page?: number,
        count?: number,
        filter_current_user_sites?: boolean,
        search?: string,
        siteState?: SiteState | string,
        organization_id?: string
    ): Promise<SitesResponse> => {
        const params = {
            page: page,
            count: count,
            filterCurrentUserSites: filter_current_user_sites,
            search: search,
            state: siteState,
            organizationId: organization_id,
        };
        return this.get<SitesResponse>(SitesEndpoints.SITES, cts, { params });
    };

    getSiteDetails = (
        cts: XHRRequestCanceler,
        id: string,
        organization_id?: string
    ): Promise<SiteDetailsResponse> => {
        const params = {
            organizationId: organization_id,
        };
        return this.get<SiteDetailsResponse>(SitesEndpoints.SITE_DETAILS(id), cts, { params });
    };

    getClients = (cts: XHRRequestCanceler, organization_id?: string): Promise<ClientsResponse> => {
        const params = {
            organizationId: organization_id,
        };
        return this.get<ClientsResponse>(SitesEndpoints.CLIENTS, cts, { params });
    };
    createSite = (
        cts: XHRRequestCanceler,
        name: string,
        state: SiteState,
        client: string,
        code: string,
        address: string,
        email_incident: string,
        phone_incident: string,
        organization_id?: string,
        conducteur_travaux_id?: string,
        chef_equipe_id?: string
    ): Promise<SiteDetailsResponse> => {
        const body = {
            name: name,
            state: state,
            client: client,
            code: code,
            address: address,
            email_incident: email_incident,
            phone_incident: phone_incident,
            organization_id: organization_id,
            conducteur_travaux_id: conducteur_travaux_id,
            chef_equipe_id: chef_equipe_id,
        };
        return this.post<SiteDetailsResponse>(SitesEndpoints.CREATE_SITE, body, cts);
    };

    updateSite = (
        id: string,
        cts: XHRRequestCanceler,
        name: string,
        state: SiteState,
        client: string,
        code: string,
        address: string,
        email_incident: string,
        phone_incident: string,
        organization_id?: string,
        conducteur_travaux_id?: string,
        chef_equipe_id?: string
    ): Promise<SiteDetailsResponse> => {
        const body = {
            name: name,
            state: state,
            client: client,
            code: code,
            address: address,
            email_incident: email_incident,
            phone_incident: phone_incident,
            organization_id: organization_id,
            conducteur_travaux_id: conducteur_travaux_id,
            chef_equipe_id: chef_equipe_id,
        };
        return this.put<SiteDetailsResponse>(SitesEndpoints.UPDATE_SITE(id), body, cts);
    };
}

const SitesDataServiceName = 'SitesDataService';
export { SitesDataService, SitesDataServiceName };
