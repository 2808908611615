import { BaseDataService } from './Base/BaseDataService';
import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { BaseApiDataResponse } from '../Models/ApiData';

const PasswordEndpoints = {
    RESET: '/api-admin/v1/password/reset',
    FORGOTTEN: '/api-admin/v1/password/forgotten',
};
export interface IPasswordDataService {
    resetPassword: (
        validationCode: string,
        email: string,
        newPassword: string,
        cts: XHRRequestCanceler
    ) => Promise<BaseApiDataResponse>;
    forgotPassword: (email: string, cts: XHRRequestCanceler) => Promise<BaseApiDataResponse>;
}
class PasswordDataService extends BaseDataService implements IPasswordDataService {
    async resetPassword(
        validationCode: string,
        email: string,
        newPassword: string,
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> {
        const body = { validation_code: validationCode, email: email, new_password: newPassword };
        try {
            const response = await this.post<BaseApiDataResponse>(
                PasswordEndpoints.RESET,
                body,
                cts,
                { noCheckRequired: true }
            );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    }
    async forgotPassword(email: string, cts: XHRRequestCanceler): Promise<BaseApiDataResponse> {
        const body = { email: email };
        return this.post<BaseApiDataResponse>(PasswordEndpoints.FORGOTTEN, body, cts);
    }
}
const PasswordDataServiceName = 'PasswordDataService';
export { PasswordDataService, PasswordDataServiceName };
