import React from 'react';
import add from '../../assets/icons/ic_add.svg';
import address from '../../assets/icons/ic_address.svg';
import addBlack from '../../assets/icons/ic_add_black.svg';
import addHovered from '../../assets/icons/ic_add_hovered.svg';
import archived from '../../assets/icons/ic_archived.svg';
import arrowDown from '../../assets/icons/ic_arrowDown.svg';
import arrowLeftBold from '../../assets/icons/ic_arrow_left_bold.svg';
import arrowRight from '../../assets/icons/ic_arrow_right.svg';
import arrowRightBold from '../../assets/icons/ic_arrow_right_bold.svg';
import calendar from '../../assets/icons/ic_calendar.svg';
import camera from '../../assets/icons/ic_camera.svg';
import check from '../../assets/icons/ic_check.svg';
import clock from '../../assets/icons/ic_clock.svg';
import close from '../../assets/icons/ic_close.svg';
import company from '../../assets/icons/ic_company.svg';
import del from '../../assets/icons/ic_delete.svg';
import disabled from '../../assets/icons/ic_disabled.svg';
import download from '../../assets/icons/ic_download.svg';
import downloadPhoto from '../../assets/icons/ic_download_photo.svg';
import email from '../../assets/icons/ic_email.svg';
import enabled from '../../assets/icons/ic_enabled.svg';
import exMark from '../../assets/icons/ic_ex-mark.svg';
import eye from '../../assets/icons/ic_eye.svg';
import gpsDisabled from '../../assets/icons/ic_gps_disabled.svg';
import gpsEnabled from '../../assets/icons/ic_gps_enabled.svg';
import hashtag from '../../assets/icons/ic_hashtag.svg';
import modifPhoto from '../../assets/icons/ic_modif_photo.svg';
import noPrestations from '../../assets/icons/ic_no_prestations.svg';
import noSites from '../../assets/icons/ic_no_sites.svg';
import iconPaginationEllipsis from '../../assets/icons/ic_pagination_ellipsis.svg';
import iconPaginationNext from '../../assets/icons/ic_pagination_next.svg';
import iconPaginationPrevious from '../../assets/icons/ic_pagination_previous.svg';
import password from '../../assets/icons/ic_password.svg';
import phone from '../../assets/icons/ic_phone.svg';
import position from '../../assets/icons/ic_position.svg';
import smallCalendar from '../../assets/icons/ic_prestationMapHeader_calendar.svg';
import smallClock from '../../assets/icons/ic_prestationMapHeader_clock.svg';
import Pen from '../../assets/icons/ic_prestationMapHeader_pen.svg';
import smallSite from '../../assets/icons/ic_prestationMapHeader_site.svg';
import smallWorker from '../../assets/icons/ic_prestationMapHeader_worker.svg';
import removeBlack from '../../assets/icons/ic_remove_black.svg';
import removeHovered from '../../assets/icons/ic_remove_hovered.svg';
import search from '../../assets/icons/ic_search.svg';
import searchError from '../../assets/icons/ic_search_error.svg';
import searchBar from '../../assets/icons/ic_search_bar.svg';
import select from '../../assets/icons/ic_select.svg';
import siteName from '../../assets/icons/ic_site_name.svg';
import triangle from '../../assets/icons/ic_triangle.svg';
import user from '../../assets/icons/ic_user.svg';
import warningRed from '../../assets/icons/ic_warning_red.svg';
import warning from '../../assets/icons/ic_warning.svg';
import worker from '../../assets/icons/ic_worker.svg';
import workerBlack from '../../assets/icons/ic_worker_black.svg';
import ctBlack from '../../assets/icons/ic_ct_black.svg';
import workersNoResults from '../../assets/icons/ic_workers_no_results.svg';
import radio from '../../assets/icons/radio_button/ic_radio_button.svg';
import radioChecked from '../../assets/icons/radio_button/ic_radio_button_checked.svg';
import tag from '../../assets/icons/ic_tag.svg';
import comment from '../../assets/icons/ic_comment.svg';
import tagLabel from '../../assets/icons/ic_tag_label.svg';
import tagLabelPlural from '../../assets/icons/ic_tag_label_plural.svg';
import circleArrowRight from '../../assets/icons/ic_circle_arrow_right.svg';
import triangleDown from '../../assets/icons/ic_triangle_down.svg';
import noMessages from '../../assets/icons/ic_no_messages.svg';
import noWorkers from '../../assets/icons/ic_no_workers.svg';

export interface IconsProps {
    wrappedIcon: JSX.Element;
}

const Icons = ({ wrappedIcon }: IconsProps): React.ReactElement => {
    return wrappedIcon;
};

export const IconExMark = Icons({ wrappedIcon: <img src={exMark} alt="exclamation-mark-icon" /> });

export const IconDownload = Icons({ wrappedIcon: <img src={download} alt="download-icon" /> });

export const IconAdd = Icons({ wrappedIcon: <img src={add} alt="add-icon" /> });

export const IconDelete = Icons({ wrappedIcon: <img src={del} alt="delete-icon" /> });

export const IconClose = Icons({ wrappedIcon: <img src={close} alt="close-icon" /> });

export const IconEmail = Icons({ wrappedIcon: <img src={email} alt="email-icon" /> });

export const IconPassword = Icons({ wrappedIcon: <img src={password} alt="password-icon" /> });

export const IconRadioUnchecked = Icons({ wrappedIcon: <img src={radio} alt="radio" /> });

export const IconRadioChecked = Icons({
    wrappedIcon: <img src={radioChecked} alt="radioChecked" />,
});

export const IconUser = Icons({ wrappedIcon: <img src={user} alt="user-icon" /> });

//* Icon Pagination
const IconPaginationPrevious = Icons({
    wrappedIcon: <img src={iconPaginationPrevious} alt="pagination-previous-icon" />,
});
const IconPaginationEllipsis = Icons({
    wrappedIcon: <img src={iconPaginationEllipsis} alt="pagination-ellipsis-icon" />,
});
const IconPaginationNext = Icons({
    wrappedIcon: <img src={iconPaginationNext} alt="pagination-next-icon" />,
});
export const CustomPaginationIcons = {
    IconPaginationPrevious,
    IconPaginationEllipsis,
    IconPaginationNext,
};

export const IconSelect = Icons({ wrappedIcon: <img src={select} alt="select" /> });

export const IconArrowDown = Icons({ wrappedIcon: <img src={arrowDown} alt="arrowDown" /> });

export const IconArrowRight = Icons({ wrappedIcon: <img src={arrowRight} alt="arrowRight" /> });

export const IconArrowLeftBold = Icons({
    wrappedIcon: <img src={arrowLeftBold} alt="arrowLeftBold" />,
});

export const IconArrowRightBold = Icons({
    wrappedIcon: <img src={arrowRightBold} alt="arrowRightBold" />,
});

//* Icon WorkerState

const IconEnabled = Icons({ wrappedIcon: <img src={enabled} alt="enabled" /> });

const IconDisabled = Icons({ wrappedIcon: <img src={disabled} alt="disabled" /> });

const IconArchived = Icons({ wrappedIcon: <img src={archived} alt="archived" /> });

export const IconState = {
    IconEnabled,
    IconDisabled,
    IconArchived,
};

export const IconWorkersNoResults = Icons({
    wrappedIcon: <img src={workersNoResults} alt="no results" />,
});

export const IconCheck = Icons({ wrappedIcon: <img src={check} alt="check" /> });

export const IconPhone = Icons({ wrappedIcon: <img src={phone} alt="phone" /> });

export const IconHashtag = Icons({ wrappedIcon: <img src={hashtag} alt="hashtag" /> });

const IconAddBlack = Icons({ wrappedIcon: <img src={addBlack} alt="add" /> });

const IconRemoveBlack = Icons({ wrappedIcon: <img src={removeBlack} alt="remove" /> });

const IconAddHovered = Icons({ wrappedIcon: <img src={addHovered} alt="add" /> });

const IconRemoveHovered = Icons({ wrappedIcon: <img src={removeHovered} alt="remove" /> });

export const IconAddAndRemove = {
    IconAddBlack,
    IconAddHovered,
    IconRemoveBlack,
    IconRemoveHovered,
};

export const IconModifPhoto = Icons({ wrappedIcon: <img src={modifPhoto} alt="modifPhoto" /> });

export const IconWorker = Icons({ wrappedIcon: <img src={worker} alt="worker" /> });

export const IconWorkerBlack = Icons({ wrappedIcon: <img src={workerBlack} alt="workerBlack" /> });

export const IconCTBlack = Icons({ wrappedIcon: <img src={ctBlack} alt="ct" /> });

export const IconTriangle = Icons({ wrappedIcon: <img src={triangle} alt="triangle" /> });

export const IconTriangleDown = Icons({ wrappedIcon: <img src={triangleDown} alt="triangleDown" /> });

export const IconNoSites = Icons({ wrappedIcon: <img src={noSites} alt="noSItes" /> });

export const IconSiteName = Icons({ wrappedIcon: <img src={siteName} alt="siteName" /> });

export const IconAddress = Icons({ wrappedIcon: <img src={address} alt="address" /> });

export const IconCompany = Icons({ wrappedIcon: <img src={company} alt="company" /> });

export const IconNoPrestations = Icons({
    wrappedIcon: <img src={noPrestations} alt="noPrestations" />,
});

const IconGpsEnabled = Icons({ wrappedIcon: <img src={gpsEnabled} alt="gpsEnabled" /> });
const IconGpsDisabled = Icons({ wrappedIcon: <img src={gpsDisabled} alt="gpsDisabled" /> });

export const IconsGps = { IconGpsEnabled, IconGpsDisabled };

export const IconCalendar = Icons({ wrappedIcon: <img src={calendar} alt="calendar" /> });

export const IconClock = Icons({ wrappedIcon: <img src={clock} alt="clock" /> });

export const IconCamera = Icons({ wrappedIcon: <img src={camera} alt="camera" /> });

export const IconPosition = Icons({ wrappedIcon: <img src={position} alt="position" /> });

export const IconDownloadPhoto = Icons({
    wrappedIcon: <img src={downloadPhoto} alt="dowloadPhoto" />,
});

export const IconSearch = Icons({ wrappedIcon: <img src={search} alt="search" /> });

export const IconSearchError = Icons({ wrappedIcon: <img src={searchError} alt="searchError" /> });

export const IconSearchBar = Icons({ wrappedIcon: <img src={searchBar} alt="searchBar" /> });

export const IconEye = Icons({ wrappedIcon: <img src={eye} alt="eye" /> });

export const IconTag = Icons({ wrappedIcon: <img src={tag} alt= "tag" /> });

export const IconComment = Icons({ wrappedIcon: <img src={comment} alt= "comment" /> });

export const IconWarningRed = Icons({ wrappedIcon: <img src={warningRed} alt="warningRed" /> });
export const IconWarning = Icons({ wrappedIcon: <img src={warning} alt="warning" /> });

export const IconTagLabel = Icons({ wrappedIcon: <img src={tagLabel} alt="tagLabel" /> });

export const IconTagLabelPlural = Icons({ wrappedIcon: <img src={tagLabelPlural} alt="tagLabelPlural" /> });

export const IconCircleArrowRight = Icons({ wrappedIcon: <img src={circleArrowRight} alt="circleArrowRight" /> });
export const IconNoMessages = Icons({ wrappedIcon: <img src={noMessages} alt="noMessages" /> });

export const IconNoWorkers = Icons({ wrappedIcon: <img src={noWorkers} alt="noWorkers" /> });

const IconSmallWorker = Icons({ wrappedIcon: <img src={smallWorker} alt="worker" /> });
const IconSite = Icons({ wrappedIcon: <img src={smallSite} alt="site" /> });
const IconSmallCalendar = Icons({ wrappedIcon: <img src={smallCalendar} alt="calendar" /> });
const IconSmallClock = Icons({ wrappedIcon: <img src={smallClock} alt="clock" /> });
const IconPen = Icons({ wrappedIcon: <img src={Pen} alt="pen" /> });

export const IconPrestationMapHeader = {
    IconSmallWorker,
    IconSite,
    IconSmallCalendar,
    IconSmallClock,
    IconPen,
};

export default Icons;
