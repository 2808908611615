import { BaseReduxService, IBaseReduxService } from '../Api/BaseReduxService';
import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import { IoC } from '../../ServicesContainer';
import { MessageResultDetailsResponse, MessageResultSummaryListResponse } from '../../../Models/Messages/Message';
import { IMessagesDataService, MessagesDataServiceName } from '../../../DataServices/MessageDataService';
import { MessagesActions } from './MessagesActions';

export interface IMessagesService extends IBaseReduxService {
    loadMessages: (
        page: number,
        count: number,
        organizationId?: string,
        cts?: XHRRequestCanceler
    ) => Promise<MessageResultSummaryListResponse>;
    getMessageDetails: (
        cts: XHRRequestCanceler,
        messageId: string,
    ) => Promise<MessageResultDetailsResponse>;
    setPage: (page: number) => void;
    createMessage: (
        message: string,
        workers: Array<string>,
        cts: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<MessageResultDetailsResponse>;
}

class MessagesService extends BaseReduxService {
    private messagesDataService: IMessagesDataService;

    constructor() {
        super();
        this.messagesDataService = IoC.get<IMessagesDataService>(MessagesDataServiceName);
    }

    public loadMessages = async (
        page: number,
        count: number,
        organizationId?: string,
        cts?: XHRRequestCanceler
    ): Promise<MessageResultSummaryListResponse> => {
        try {
            const response: MessageResultSummaryListResponse =
                await this.messagesDataService.getMessages(
                    page,
                    count,
                    organizationId,
                    cts
                );

            this.dispatch(MessagesActions.setMessagesInitialized(true));
            this.dispatch(MessagesActions.setMessages(response.data));

            return Promise.resolve(response);
        } catch (error) {
            this.dispatch(MessagesActions.setMessages([]));
            this.dispatch(MessagesActions.setMessagesInitialized(true));
            return Promise.reject(error);
        }
    };

    public getMessageDetails = async (
        cts: XHRRequestCanceler,
        messageId: string,
    ): Promise<MessageResultDetailsResponse> => {
        try {
            const response: MessageResultDetailsResponse =
                await this.messagesDataService.getMessageDetails(
                    cts,
                    messageId,
                );

            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public setPage = (page: number) => {
        this.dispatch(MessagesActions.setMessagesPage(page));
    };

    public createMessage = async (
        message: string,
        workers: Array<string>,
        cts: XHRRequestCanceler,
        organization_id?: string
    ): Promise<MessageResultDetailsResponse> => {
        try {
            const response: MessageResultDetailsResponse =
                await this.messagesDataService.createMessage(
                    message,
                    workers,
                    cts,
                    organization_id
                );

            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

const MessagesServiceName = 'MessagesService';
export { MessagesService, MessagesServiceName };
