import * as AuthenticationTypes from './typings/AuthenticationActionTypes';
import { AuthenticationState } from './typings/AuthenticationState';
import * as EmailActionTypes from './typings/EmailActionTypes';

const initialState: AuthenticationState = {
    isAuthenticated: false,
    initialized: false,
    error: null,

    // EMAIL
    access_token: '',
    refresh_token: '',
    expires_in: 0, // seconds, from when the token was acquired
    token_type: '',
    expiration_date: new Date(0).toISOString(), // ISO string
    first_login: false,

    /** The authorization header for the current login method */
    authorization: '',
};

const AuthenticationReducer = (
    state = initialState,
    action: AuthenticationTypes.AuthenticationAction | EmailActionTypes.EmailAction
): AuthenticationState => {
    switch (action.type) {
        default:
            return state;
        case AuthenticationTypes.AuthActionType.SET_FIRST_LOGIN:
            return { ...state, first_login: action.payload.first_login };
        case AuthenticationTypes.AuthActionType.LOG_OUT:
            return { ...initialState, initialized: true };
        case AuthenticationTypes.AuthActionType.SET_INITIALIZED:
            return { ...state, initialized: action.payload.initialized };
        case AuthenticationTypes.AuthActionType.SET_AUTHENTICATED:
            return { ...state, isAuthenticated: action.payload.isAuthenticated };
        case AuthenticationTypes.AuthActionType.SET_AUTHORIZATION_HEADER:
            return { ...state, authorization: action.payload.value };
        case AuthenticationTypes.AuthActionType.CLEAR_AUTHORIZATION_HEADER:
            return { ...state, authorization: '' };

        //* EMAIL
        case EmailActionTypes.EmailActionType.LOGIN_EMAIL_SUCCESS:
            // action.payload : access_token, refresh_token, expires_in, token_type, expiration_date
            return { ...state, ...action.payload, error: false };
        case EmailActionTypes.EmailActionType.LOGIN_EMAIL_FAIL:
            // return initial state but preserve login method
            return { ...initialState, initialized: true, error: action.payload.error };
        case EmailActionTypes.EmailActionType.REFRESH_EMAIL_SUCCESS:
            // action.payload : access_token, refresh_token, expires_in, token_type, expiration_date
            return { ...state, ...action.payload, error: false };
        case EmailActionTypes.EmailActionType.REFRESH_EMAIL_FAIL:
            // return initial state (logged out) but preserve login method & set initialized
            return { ...initialState, initialized: true, error: action.payload.error };
    }
};

export { AuthenticationReducer };
