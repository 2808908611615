import { Prestation } from '../../../../Models/Prestations/Prestations';

export enum PrestationsActionsTypes {
    SET_PRESTATIONS_LIST = 'app/prestations/set/prestations',
    SET_PRESTATIONS_INITIALIZED = 'app/prestations/set/initialized',
    SET_PRESTATIONS_ANOMALY = 'app/prestations/set/anomaly',
    SET_PRESTATIONS_FILTER_CURRENT_USER_SITES = 'app/prestations/set/filter_current_user_sites',
    SET_PRESTATIONS_COMMENT = 'app/prestations/set/comment',
    SET_PRESTATIONS_CLOCKING_ANOMALY = 'app/prestations/set/clocking_anomaly',
    SET_PRESTATIONS_START_DATE = 'app/prestations/set/startDate',
    SET_PRESTATIONS_END_DATE = 'app/prestations/set/endDate',
    SET_PRESTATIONS_SEARCH = 'app/prestations/set/search',
    SET_PRESTATIONS_PAGE = 'app/prestations/set/page',
}

export interface PrestationsActionSetPrestationsList {
    type: PrestationsActionsTypes.SET_PRESTATIONS_LIST;
    payload: { prestations: Array<Prestation> };
}

export interface PrestationsActionSetPrestationsInitialized {
    type: PrestationsActionsTypes.SET_PRESTATIONS_INITIALIZED;
    payload: { initialized: boolean };
}

export interface PrestationsActionSetPrestationsAnomaly {
    type: PrestationsActionsTypes.SET_PRESTATIONS_ANOMALY;
    payload: { anomaly: boolean };
}

export interface PrestationsActionSetPrestationsFilterCurrentUserSites {
    type: PrestationsActionsTypes.SET_PRESTATIONS_FILTER_CURRENT_USER_SITES;
    payload: { filter_current_user_sites: boolean };
}

export interface PrestationsActionSetPrestationsComment {
    type: PrestationsActionsTypes.SET_PRESTATIONS_COMMENT;
    payload: { comment: boolean };
}

export interface PrestationsActionSetPrestationsClockingAnomaly {
    type: PrestationsActionsTypes.SET_PRESTATIONS_CLOCKING_ANOMALY;
    payload: { clocking_anomaly: boolean };
}

export interface PrestationsActionSetPrestationsStartDate {
    type: PrestationsActionsTypes.SET_PRESTATIONS_START_DATE;
    payload: { startDate: Date };
}

export interface PrestationsActionSetPrestationsEndDate {
    type: PrestationsActionsTypes.SET_PRESTATIONS_END_DATE;
    payload: { endDate: Date };
}

export interface PrestationsActionSetPrestationsSearch {
    type: PrestationsActionsTypes.SET_PRESTATIONS_SEARCH;
    payload: { search: string };
}

export interface PrestationsActionSetPrestationsPage {
    type: PrestationsActionsTypes.SET_PRESTATIONS_PAGE;
    payload: { page: number };
}

export type PrestationsActions =
    | PrestationsActionSetPrestationsList
    | PrestationsActionSetPrestationsInitialized
    | PrestationsActionSetPrestationsAnomaly
    | PrestationsActionSetPrestationsFilterCurrentUserSites
    | PrestationsActionSetPrestationsComment
    | PrestationsActionSetPrestationsClockingAnomaly
    | PrestationsActionSetPrestationsStartDate
    | PrestationsActionSetPrestationsEndDate
    | PrestationsActionSetPrestationsSearch
    | PrestationsActionSetPrestationsPage;
