import orange from '@material-ui/core/colors/deepOrange';
import { createTheme } from '@mui/material/styles';
import MuiThemeProviderOrigin from '@material-ui/core/styles/MuiThemeProvider';
import PropTypes from 'prop-types';
import React from 'react';

const theme = createTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#3fc482',
        },
        secondary: orange,
        type: 'light',
    },
    overrides: {
        MuiSwitch: {
            root: {
                width: 44,
                height: 22,
                padding: 0,
                display: 'flex',
            },
            thumb: {
                boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
                width: 18,
                height: 18,
                borderRadius: 10,
            },
            switchBase: {
                padding: 2,
                '&.Mui-checked': {
                    transform: 'translateX(22px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                        opacity: 1,
                    },
                },
            },
            colorSecondary: {
                '&$checked': {
                    color: '#9CA99C',
                },
            },
            track: {
                borderRadius: 22 / 2,
                boxSizing: 'border-box',
                '$checked$checked + &': {
                    opacity: 1,
                    backgroundColor: '#29322F',
                },
            },
        },
    },
});

const MuiThemeProvider = ({ children }) => {
    return <MuiThemeProviderOrigin theme={theme}>{children}</MuiThemeProviderOrigin>;
};

MuiThemeProvider.propTypes = {
    children: PropTypes.node,
};

MuiThemeProvider.defaultProps = {
    children: null,
};

export { MuiThemeProvider };
