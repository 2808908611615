import qs, { IStringifyOptions } from 'qs';
import Axios, { AxiosRequestConfig, Canceler } from 'axios';
import { XHRRequestCanceler } from './XHRRequestCanceler';

export interface XHRRequestConfig extends AxiosRequestConfig {
    noCheckRequired?: boolean;
}

export const qsOptions: IStringifyOptions = {
    indices: false,
    arrayFormat: 'repeat',
    addQueryPrefix: false,
};

export const defaultSerializeRequestParameters = (params: any): string => {
    return qs.stringify(params, { ...qsOptions });
};

export const getAxiosRequestConfig = (
    cts?: XHRRequestCanceler,
    config?: XHRRequestConfig
): AxiosRequestConfig => {
    return {
        ...config,
        cancelToken: new Axios.CancelToken((canceler: Canceler): void =>
            cts?.setCanceler(canceler)
        ),
    };
};

export const getAxiosRequestUploadConfig = (
    cts?: XHRRequestCanceler,
    config?: XHRRequestConfig,
    progress?: (value: number) => void
): AxiosRequestConfig => {
    return {
        ...config,
        cancelToken: new Axios.CancelToken((canceler: Canceler): void =>
            cts?.setCanceler(canceler)
        ),
        onUploadProgress: (progressEvent: any) => {
            if (progress) {
                const percent =
                    Math.round((progressEvent.loaded * 100) / progressEvent.total) / 100;
                //* percent from 0.0 to 1.0
                progress(percent);
            }
        },
    };
};
