import { Ability } from '@casl/ability';
import { UserInfo } from '../../../../../Models/Users/Users';

export enum MeActionType {
    SET_INITIALIZED = 'app/me/set/initialized',
    SET_INFO = 'app/me/set/info',
    SET_ABILITY = 'app/me/set/ability',
    SET_ACCEPT_CGU = 'app/me/set/cgu',
    SET_SUPER_ADMIN_TEAM_USER_LABELS = 'app/me/set/super_admin_team_user_labels',
}

export interface SetInitializedAction {
    type: typeof MeActionType.SET_INITIALIZED;
    payload: { initialized: boolean };
}

export interface SetInfoAction {
    type: typeof MeActionType.SET_INFO;
    payload: { info?: UserInfo };
}

export interface SetAbilityAction {
    type: typeof MeActionType.SET_ABILITY;
    payload: { ability: Ability; updaterName: string };
}

export interface SetAcceptCguAction {
    type: typeof MeActionType.SET_ACCEPT_CGU;
    payload: { cgu: boolean };
}

export interface SetSuperAdminTeamUserLabelsAction {
    type: typeof MeActionType.SET_SUPER_ADMIN_TEAM_USER_LABELS;
    payload: { teamUserLabel: string; teamUserPluralLabel: string; };
}

export type MeAction = SetInitializedAction | SetInfoAction | SetAbilityAction | SetAcceptCguAction | SetSuperAdminTeamUserLabelsAction;
