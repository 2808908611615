import { Organization } from '../../../models/Organizations/Organizations';
import { OrganizationsActionSetOrganizationsList, OrganizationsActionsTypes } from './typings/OrganizationsActionsTypes';

export class OrganizationsActions {
    static SetOrganizationsInitialized = (initialized: boolean) => ({
        type: OrganizationsActionsTypes.SET_ORGANIZATIONS_INITIALIZED,
        payload: { initialized },
    });

    static SetOrganizationsList = (sites: Array<Organization>): OrganizationsActionSetOrganizationsList => ({
        type: OrganizationsActionsTypes.SET_ORGANIZATIONS_LIST,
        payload: { sites },
    });

    static SetOrganizationsState = (state: string) => ({
        type: OrganizationsActionsTypes.SET_ORGANIZATIONS_STATE,
        payload: { state },
    });

    static SetOrganizationsPage = (page: number) => ({
        type: OrganizationsActionsTypes.SET_ORGANIZATIONS_PAGE,
        payload: { page },
    });

    static SetLastUsedOrganizationId = (last_used_organization_id: string) => ({
        type: OrganizationsActionsTypes.SET_LAST_USED_ORGANIZATION_ID,
        payload: { last_used_organization_id },
    });
}
