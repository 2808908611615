import { BaseDataService } from './Base/BaseDataService';
import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { Constants } from '../Constants';
import {
    IncidentResultDetailsResponse,
    IncidentResultSummaryListResponse,
} from '../Models/Incidents/Incident';

const IncidentEndpoints = {
    INCIDENTS: '/api-admin/v1/incidents',
    INCIDENT_DETAILS: (id: string) => `/api-admin/v1/incidents/${id}`,
};

export interface IIncidentsDataService {
    getIncidents: (
        filter_current_user_sites: boolean,
        page?: number,
        count?: number,
        organization_id?: string,
        cts?: XHRRequestCanceler
    ) => Promise<IncidentResultSummaryListResponse>;

    getIncidentDetails: (
        cts: XHRRequestCanceler,
        prestationId: string,
        organization_id?: string
    ) => Promise<IncidentResultDetailsResponse>;

    updateIncident: (
        cts: XHRRequestCanceler,
        incidentId: string,
        is_solved: boolean,
        organization_id?: string
    ) => Promise<IncidentResultDetailsResponse>;

    downloadPhoto: (photoUrl: string) => Promise<Response>;
}

class IncidentsDataService extends BaseDataService implements IIncidentsDataService {
    getIncidents = (
        filter_current_user_sites: boolean,
        page?: number,
        count?: number,
        organization_id?: string,
        cts?: XHRRequestCanceler
    ): Promise<IncidentResultSummaryListResponse> => {
        const params = {
            filterCurrentUserSites: filter_current_user_sites,
            page: page || 0,
            count: count || Constants.DEFAULT_ITEM_COUNT_PER_PAGE,
            organizationId: organization_id,
        };
        return this.get<IncidentResultSummaryListResponse>(IncidentEndpoints.INCIDENTS, cts, {
            params,
        });
    };

    getIncidentDetails = (
        cts: XHRRequestCanceler,
        prestationId: string,
        organization_id?: string
    ): Promise<IncidentResultDetailsResponse> => {
        const params = {
            organizationId: organization_id,
        };
        return this.get<IncidentResultDetailsResponse>(
            IncidentEndpoints.INCIDENT_DETAILS(prestationId),
            cts,
            { params }
        );
    };

    updateIncident = (
        cts: XHRRequestCanceler,
        incidentId: string,
        is_solved: boolean,
        organization_id?: string
    ): Promise<IncidentResultDetailsResponse> => {
        const body = {
            organization_id,
            is_solved,
        };
        return this.patch<IncidentResultDetailsResponse>(
            IncidentEndpoints.INCIDENT_DETAILS(incidentId),
            body,
            cts
        );
    };

    downloadPhoto = (photoUrl: string): Promise<Response> => {
        return fetch(photoUrl);
    };
}
const IncidentsDataServiceName = 'IncidentsDataService';
export { IncidentsDataService, IncidentsDataServiceName };
