import { ApiDataResponse, BasePaginatedListResponse } from '../ApiData';

export enum BackOfficeUserState {
    All = 'All',
    Enabled = 'Enabled',
    Archived = 'Archived',
}

export enum BackOfficeUserRole {
    ReadOnly = 'ReadOnly',
    User = 'User',
    Manager = 'Manager',
    Admin = 'Admin',
    SuperAdmin = 'SuperAdmin',
}

export interface User {
    id: string;
    first_name: string;
    last_name: string;
    state: BackOfficeUserState;
    role: BackOfficeUserRole;
}

export interface UserInfo extends User {
    email: string;
    organization_id?: string;
    accepted_cgu: boolean;
    team_user_label: string;
    team_user_plural_label: string;
    has_messaging_access: boolean;
}

export interface CreateOrUpdateBackOfficeUserRequest {
    first_name: string;
    last_name: string;
    email: string;
    state: BackOfficeUserState;
    role: BackOfficeUserRole;
    organization_id?: string;
}

//* RESPONSE
export type MeResponse = ApiDataResponse<UserInfo>;

export type BackOfficeUsersResponse = BasePaginatedListResponse<User>;

export type UserResult = ApiDataResponse<UserInfo>;
