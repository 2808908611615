import './customToast.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import className from 'classnames';
import IconButton from '@material-ui/core/IconButton';

import { IconClose } from '../../Icons/Icons';
import iconError from '../../../assets/icons/toast/error.svg';
import iconSuccess from '../../../assets/icons/toast/success.svg';
import iconInfo from '../../../assets/icons/toast/info.svg';

enum CustomToastType {
    ERROR = 'Error',
    SUCCESS = 'Success',
    INFO = 'Info',
}

interface CustomToastProps {
    type: CustomToastType;
    content: React.ReactNode;
}

const CustomToast = ({ type, content }: CustomToastProps): React.FunctionComponent => {
    const toastClass = className('custom-toast-container', { [type]: type });
    let icon: string;

    switch (type) {
        case 'Error':
            icon = iconError;
            break;
        case 'Success':
            icon = iconSuccess;
            break;
        default:
            icon = iconInfo;
    }
    const ToastContainer: React.FunctionComponent = ({ closeToast }: any) => {
        return (
            <div className={toastClass}>
                <img src={icon} alt="delete" className="toast-icon" />
                <div className="body">
                    <div>
                        <div className="title">
                            <FormattedMessage id={`Toast_Title${type}`} />
                        </div>
                        <div className="content">{content}</div>
                    </div>
                    <IconButton className="close-icon" onClick={closeToast} children={IconClose} />
                </div>
            </div>
        );
    };

    return ToastContainer;
};

export { CustomToast, CustomToastType };
