import { Unsubscribe } from 'redux';

import { AppState } from '../../../../Configs/store/AppReducer';
import { store } from '../../../../Configs/store/store';
import { IErrorHandler, ErrorHandlerName } from '../../../../Libs/xhr/ErrorHandler';
import { IoC } from '../../ServicesContainer';

export interface IBaseReduxService {
    getState: () => AppState;
    dispatch: <T>(action: T) => T;
    subscribe: (listener: (appStore: AppState) => void) => Unsubscribe;
}

export class BaseReduxService implements IBaseReduxService {
    public getState = (): AppState => store.getState();

    public dispatch = <T>(action: T): T => store.dispatch(action);

    public subscribe = (listener: (appStore: AppState) => void): Unsubscribe => {
        return store.subscribe(() => listener(store.getState()));
    };

    public unsubscribe = (listener: (appStore: AppState) => void): Unsubscribe => {
        return store.subscribe(() => listener(store.getState()));
    };

    public handleApiError = (data: any, disableToast = false) => {
        try {
            const errorHandler = IoC.get<IErrorHandler>(ErrorHandlerName);
            errorHandler.handle(data);
        } catch (error) {
            console.log('handleApiError', { error });
        }
    };
}
