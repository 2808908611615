import * as BackOfficeUsersActionTypes from './typings/BackOfficeUsersActionTypes';
import { BackOfficeUsersState } from './typings/BackOfficeUsersState';

const initialState: BackOfficeUsersState = {
    initialized: false,
    items: [],
    page: 0,
};

const BackOfficeUsersReducer = (
    state = initialState,
    action: BackOfficeUsersActionTypes.BackOfficeUsersAction
): BackOfficeUsersState => {
    switch (action.type) {
        default:
            return state;
        case BackOfficeUsersActionTypes.BackOfficeUsersActionType.SET_USERS_INITIALIZED:
            return { ...state, initialized: action.payload.initialized };
        case BackOfficeUsersActionTypes.BackOfficeUsersActionType.SET_USERS:
            return { ...state, items: action.payload.items };
        case BackOfficeUsersActionTypes.BackOfficeUsersActionType.SET_PAGE:
            return { ...state, page: action.payload.page };
    }
};

export { BackOfficeUsersReducer };
