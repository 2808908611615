import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../Configs/store/AppReducer';
import { BackOfficeUserRole } from '../../../../Core/Models/Users/Users';
import { MeState } from '../../../../Core/Services/Data/Users/Me/typings/MeState';
import { UserUtils } from '../../../../Core/Services/Data/Users/UserUtils';
import { useIntl } from 'react-intl';

export interface UserLoggedState {
    initialized: boolean;
    name: string;
    role?: BackOfficeUserRole;
    organization_id?: string;
    accepted_cgu: boolean;
    team_user_label: string;
    team_user_plural_label: string;
    has_messaging_access: boolean;
}

const DefaultUserLoggedState: UserLoggedState = {
    initialized: false,
    name: '',
    accepted_cgu: false,
    team_user_label: '',
    team_user_plural_label: '',
    has_messaging_access: false,
};

export const useAuthenticatedUser = () => {
    const [state, setState] = useState(DefaultUserLoggedState);
    const me: MeState = useSelector((state: AppState) => state.Me);
    const i18n = useIntl();

    useEffect(() => {
        setState({
            initialized: me?.initialized,
            name: UserUtils.getFullName(me?.data),
            role: me?.data?.role,
            organization_id: me?.data?.organization_id,
            accepted_cgu: me?.data?.accepted_cgu || false,
            team_user_label: me?.data?.team_user_label ?? i18n.formatMessage({ id: 'Worker_Common_Label' }),
            team_user_plural_label: me?.data?.team_user_plural_label ?? i18n.formatMessage({ id: 'Worker_Common_Label_Plural' }),
            has_messaging_access: me?.data?.has_messaging_access || false,
        });
    }, [me]);

    return state;
};
