import { Organization, OrganizationState } from '../../../../Models/Organizations/Organizations';

export enum OrganizationsActionsTypes {
    SET_ORGANIZATIONS_LIST = 'app/organizations/set/organizations',
    SET_ORGANIZATIONS_STATE = 'app/organizations/set/state',
    SET_ORGANIZATIONS_PAGE = 'app/organizations/set/page',
    SET_ORGANIZATIONS_INITIALIZED = 'app/organizations/set/initialized',
    SET_LAST_USED_ORGANIZATION_ID = 'app/organizations/set/last_used_organization_id',
}

export interface OrganizationsActionSetOrganizationsList {
    type: OrganizationsActionsTypes.SET_ORGANIZATIONS_LIST;
    payload: { sites: Array<Organization> };
}

export interface OrganizationsActionSetOrganizationsState {
    type: OrganizationsActionsTypes.SET_ORGANIZATIONS_STATE;
    payload: { state: OrganizationState };
}

export interface OrganizationsActionSetOrganizationsPage {
    type: OrganizationsActionsTypes.SET_ORGANIZATIONS_PAGE;
    payload: { page: number };
}

export interface OrganizationsActionSetOrganizationsInitialized {
    type: OrganizationsActionsTypes.SET_ORGANIZATIONS_INITIALIZED;
    payload: { initialized: boolean };
}

export interface OrganizationsActionSetLastUsedOrganizationId {
    type: OrganizationsActionsTypes.SET_LAST_USED_ORGANIZATION_ID;
    payload: { last_used_organization_id: string };
}

export type OrganizationsActions =
    | OrganizationsActionSetOrganizationsList
    | OrganizationsActionSetOrganizationsState
    | OrganizationsActionSetOrganizationsPage
    | OrganizationsActionSetOrganizationsInitialized
    | OrganizationsActionSetLastUsedOrganizationId;
