import * as WorkersActionsTypes from './typings/WorkersActionsTypes';
import { WorkersState } from './typings/WorkersState';

const initialState: WorkersState = {
    initialized: false,
    items: [],
    page: 0,
    filters: {
        search: '',
        state: '',
        type: '',
    },
};

const WorkersReducer = (
    state = initialState,
    action: WorkersActionsTypes.WorkersActions
): WorkersState => {
    switch (action.type) {
        case WorkersActionsTypes.WorkersActionsTypes.SET_WORKERS:
            return { ...state, items: action.payload.items };
        case WorkersActionsTypes.WorkersActionsTypes.SET_WORKERS_INITIALIZED:
            return { ...state, initialized: action.payload.initialized };
        case WorkersActionsTypes.WorkersActionsTypes.SET_WORKERS_LIST_PAGE:
            return { ...state, page: action.payload.page };
        case WorkersActionsTypes.WorkersActionsTypes.SET_WORKERS_SEARCH:
            return { ...state, filters: { ...state.filters, search: action.payload.search } };
        case WorkersActionsTypes.WorkersActionsTypes.SET_WORKERS_STATE:
            return { ...state, filters: { ...state.filters, state: action.payload.state } };
        case WorkersActionsTypes.WorkersActionsTypes.SET_WORKERS_TYPE:
            return { ...state, filters: { ...state.filters, type: action.payload.type } };
        default:
            return state;
    }
};

export { WorkersReducer };
