import { Site, SiteWithStatus } from '../../../Models/Sites/Sites';
import { PrestationWorker, Worker, WorkerState, WorkerType, WorkerWithStatus } from '../../../models/Workers/Workers';
import { Messages } from '../I18n/typings/LocalMessageActionTypes';

export class WorkerUtils {
    static getFullName(worker?: Worker | PrestationWorker): string {
        return worker ? `${worker.last_name} ${worker.first_name}` : '';
    }

    static toFullName = (firstName: string, lastName: string): string => {
        return `${firstName} ${lastName}`;
    };

    static toWorkerStateTitle = (messages: Messages, state: WorkerState | string) => {
        return messages[`Worker_State_${state}`];
    };

    static toWorkerTypeTitle = (messages: Messages, type: WorkerType | string) => {
        return messages[`Worker_Type_${type}`];
    };

    static toMapActiveSites = (
        allSites: Array<Site>,
        selected: Array<Site>
    ): Array<SiteWithStatus> => {
        let selectedObject: { [key: string]: boolean } = {};

        selected.forEach((elem) => (selectedObject = { ...selectedObject, [elem.id]: true }));

        return allSites.map((elem) => {
            return { ...elem, active: selectedObject[elem.id] ? true : false };
        });
    };

    static toMapActiveWorkers = (
        allWorkers: Array<Worker>,
        selected: Array<Worker>
    ): Array<WorkerWithStatus> => {
        let selectedObject: { [key: string]: boolean } = {};

        selected.forEach((elem) => (selectedObject = { ...selectedObject, [elem.id]: true }));

        return allWorkers.map((elem) => {
            return { ...elem, active: selectedObject[elem.id] ? true : false };
        });
    }

    static getIdOfSites = (sites: Array<Site>) => {
        return sites.map((elem) => elem.id);
    };
}
