import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import { Constants } from '../../../Constants';
import {
    IOrganizationDataService,
    OrganizationDataServiceName,
} from '../../../DataServices/OrganizationDataService';
import {
    OrganizationDetailsResult,
    OrganizationResult,
    OrganizationState,
    StatisticsResult,
} from '../../../models/Organizations/Organizations';
import { ExportUtils } from '../../../Utils/ExportUtils';
import { IoC } from '../../ServicesContainer';
import { BaseReduxService } from '../Api/BaseReduxService';
import { OrganizationsActions } from './OrganizationsActions';

export interface IOrganizationService extends IOrganizationDataService {
    changePage: (page: number) => void;
    changeState: (state: string) => void;
    exportStatistics: (
        startDate: Date,
        endDate: Date,
        auth: string,
        organization_id?: string
    ) => void;
}

class OrganizationsService extends BaseReduxService implements IOrganizationService {
    private readonly organizationDataService: IOrganizationDataService;

    constructor() {
        super();
        this.organizationDataService = IoC.get<IOrganizationDataService>(
            OrganizationDataServiceName
        );
    }

    getOrganizations = async (
        cts: XHRRequestCanceler,
        page?: number,
        count?: number,
        state?: OrganizationState | string,
    ): Promise<OrganizationResult> => {
        try {
            const response: OrganizationResult = await this.organizationDataService.getOrganizations(
                cts,
                page,
                count,
                state
            );
            this.dispatch(OrganizationsActions.SetOrganizationsList(response.data));
            this.dispatch(OrganizationsActions.SetOrganizationsInitialized(true));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    getOrganizationDetails = async (
        cts: XHRRequestCanceler,
        id: string
    ): Promise<OrganizationDetailsResult> => {
        try {
            const response = await this.organizationDataService.getOrganizationDetails(cts, id);
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    createOrganization = async (
        cts: XHRRequestCanceler,
        name: string,
        team_user_label: string,
        team_user_plural_label: string,
        adminFirstName: string,
        adminLastName: string,
        adminEmail: string,
        has_messaging_access: boolean
    ): Promise<OrganizationDetailsResult> => {
        try {
            const response = await this.organizationDataService.createOrganization(
                cts,
                name,
                team_user_label,
                team_user_plural_label,
                adminFirstName,
                adminLastName,
                adminEmail,
                has_messaging_access
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };
    updateOrganization = async (
        id: string,
        cts: XHRRequestCanceler,
        company_name: string,
        team_user_label: string,
        team_user_plural_label: string,
        kbis: string,
        tva: string,
        address: string,
        organization_phone_number: string,
        state: OrganizationState,
        contact_first_name: string,
        contact_last_name: string,
        contact_phone_number: string,
        contact_email: string,
        show_calendar_hour: boolean,
        has_messaging_access: boolean
    ): Promise<OrganizationDetailsResult> => {
        try {
            const response = await this.organizationDataService.updateOrganization(
                id,
                cts,
                company_name,
                team_user_label,
                team_user_plural_label,
                kbis,
                tva,
                address,
                organization_phone_number,
                state,
                contact_first_name,
                contact_last_name,
                contact_phone_number,
                contact_email,
                show_calendar_hour,
                has_messaging_access
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    getStatistics = async (
        cts: XHRRequestCanceler,
        start_date: Date,
        end_date: Date,
        organization_id?: string
    ): Promise<StatisticsResult> => {
        try {
            const response = await this.organizationDataService.getStatistics(
                cts,
                start_date,
                end_date,
                organization_id
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public exportStatistics = async (
        startDate: Date,
        endDate: Date,
        auth: string,
        organization_id?: string
    ) => {
        ExportUtils.downloadFileFromUrl(
            ExportUtils.getExportUrlWithParams({
                url: Constants.getRoot() + '/api-admin/v1/statistics/export',
                auth: auth,
                startDate: startDate,
                endDate: endDate,
                organization_id: organization_id,
            })
        );
    };

    public changePage = (page: number): void => {
        this.dispatch(OrganizationsActions.SetOrganizationsPage(page));
    };

    public changeState = (state: string): void => {
        this.dispatch(OrganizationsActions.SetOrganizationsState(state));
    };
}

const OrganizationsServiceName = 'OrganizationsService';
export { OrganizationsService, OrganizationsServiceName };
