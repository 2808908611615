import { IncidentResultSummary } from '../../../Models/Incidents/Incident';
import {
    IncidentsActionType,
    SetIncidentsAction,
    SetIncidentsFilterCurrentUserSitesAction,
    SetIncidentsInitializedAction,
    SetIncidentsPageAction,
} from './typings/IncidentsActionTypes';

export class IncidentsActions {
    static setIncidentsInitialized = (initialized: boolean): SetIncidentsInitializedAction => ({
        type: IncidentsActionType.SET_INCIDENTS_INITIALIZED,
        payload: { initialized },
    });

    static setIncidents = (items: Array<IncidentResultSummary>): SetIncidentsAction => ({
        type: IncidentsActionType.SET_INCIDENTS,
        payload: { items },
    });

    static setIncidentsFilterCurrentUserSites = (filter_current_user_sites: boolean): SetIncidentsFilterCurrentUserSitesAction => ({
        type: IncidentsActionType.SET_FILTER_CURRENT_USER_SITES,
        payload: { filter_current_user_sites },
    });

    static setIncidentsPage = (page: number): SetIncidentsPageAction => ({
        type: IncidentsActionType.SET_PAGE,
        payload: { page },
    });
}
