import { persistor, store } from './Configs/store/store';
import { CoreBootstrapper } from './Core/CoreBootstrapper';
import {
    INavigationService,
    NavigationServiceName,
} from './Core/Services/Contracts/INavigationService';
import { IToastService, ToastServiceName } from './Core/Services/Contracts/IToastService';
import { IoC } from './Core/Services/ServicesContainer';
import { AppRouteType } from './Web/Components/Templates/AppRouter/AppRouteType';
import { DEFAULT_ROUTE, getAppRoutes } from './Web/Routers/AppRoutes';
import { NavigationService } from './Web/Services/NavigationService';
import { ToastService } from './Web/Services/ToastService';

export interface BootstrapperInitialized {
    store: any;
    persistor: any;
    mountActions: any;
    defaultRoote: string;
    routes: Array<AppRouteType>;
}

export class AppBootstrapper extends CoreBootstrapper {
    public initialize = (): BootstrapperInitialized => {
        //* Initialize Services
        this.initializeWebServices();

        this.initializeCoreServices();
        //* Initialize store and api client
        this.initializeCore();

        const routes = getAppRoutes();
        return {
            store,
            routes,
            persistor,
            mountActions: [],
            defaultRoote: DEFAULT_ROUTE,
        };
    };

    private initializeWebServices = (): void => {
        IoC.bind<IToastService>(ToastServiceName).toConstantValue(new ToastService());
        IoC.bind<INavigationService>(NavigationServiceName).toConstantValue(
            new NavigationService()
        );
    };
}
