import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import {
    AuthenticationResponse,
    LoginRequest,
    GrantType,
    RefreshRequest,
} from '../Models/Authentication/Login';
import { Constants } from '../Constants';
import { BaseDataService } from './Base/BaseDataService';

export const END_POINTS = {
    LOGIN: '/api-admin/v1/login',
    REFRESH: '/api-admin/v1/refresh',
    LOGOUT: '/api-admin/v1/logout',
};

export interface IAuthDataService {
    login: (
        email: string,
        password: string,
        cts: XHRRequestCanceler
    ) => Promise<AuthenticationResponse>;
    refresh: (refreshToken: string, cts?: XHRRequestCanceler) => Promise<AuthenticationResponse>;
}

class AuthDataService extends BaseDataService implements IAuthDataService {
    async login(
        email: string,
        password: string,
        cts: XHRRequestCanceler
    ): Promise<AuthenticationResponse> {
        const body: LoginRequest = {
            email: email,
            password: password,
            grant_type: GrantType.Password,
            client_id: Constants.CLIENT_ID,
            client_secret: Constants.CLIENT_SECRET,
        };
        return this.post<AuthenticationResponse>(END_POINTS.LOGIN, body, cts, {
            noCheckRequired: true,
        });
    }
    async refresh(refreshToken: string, cts?: XHRRequestCanceler): Promise<AuthenticationResponse> {
        const body: RefreshRequest = {
            refresh_token: refreshToken,
            client_id: Constants.CLIENT_ID,
            client_secret: Constants.CLIENT_SECRET,
        };
        return this.post<AuthenticationResponse>(END_POINTS.REFRESH, body, cts, {
            noCheckRequired: true,
        });
    }
}

const AuthDataServiceName = 'AuthDataService';
export { AuthDataService, AuthDataServiceName };
