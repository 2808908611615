import { AppActionType } from '../App/AppActions';
import { getNavigationService } from '../Services/Contracts/INavigationService';
import { IoC } from '../Services/ServicesContainer';
import { xhrClient } from '../Services/Data/Api/ApiService';
import { AuthServiceName, IAuthService } from '../Services/Data/Authentication/AuthenticationService';
import * as AuthenticationTypes from '../Services/Data/Authentication/typings/AuthenticationActionTypes';
import { EmailActionType } from '../Services/Data/Authentication/typings/EmailActionTypes';

const AuthenticationMiddleware = ({ dispatch, getState }: { dispatch: any, getState: any }) => (next: any) => (action: any) => {
    if (action) {
        switch (action.type) {
            case AuthenticationTypes.AuthActionType.LOG_OUT:
                getNavigationService().goToLogin();
                break;
            case AppActionType.APP_ESSENTIAL_RELOAD:
                const res = next(action);
                const authService = IoC.get<IAuthService>(AuthServiceName);
                authService.refreshAuthentication().catch((err: any) => err);
                return res;
            case EmailActionType.LOGIN_EMAIL_SUCCESS:
            case EmailActionType.REFRESH_EMAIL_SUCCESS:
                xhrClient.setAuthorization(`Bearer ${action.payload.access_token}`);
                break;
            case EmailActionType.LOGIN_EMAIL_FAIL:
            case EmailActionType.REFRESH_EMAIL_FAIL:
                xhrClient.setAuthorization('');
                break;
            default:
                break;
        }
    }

    return next(action);
};

export { AuthenticationMiddleware };
