import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import {
    IWorkersDataService,
    WorkersDataServiceName,
} from '../../../DataServices/WorkersDataService';
import { Site } from '../../../models/Sites/Sites';
import {
    WorkerState,
    WorkerType,
    WorkersDetailsResponse,
    WorkersResponse,
} from '../../../models/Workers/Workers';
import { IoC } from '../../ServicesContainer';
import { BaseReduxService, IBaseReduxService } from '../Api/BaseReduxService';
import { WorkersActions } from './WorkersActions';
import { WorkerUtils } from './WorkerUtils';
import { BaseApiDataResponse } from '../../../Models/ApiData';
import { ExportUtils } from '../../../Utils/ExportUtils';
import { Constants } from '../../../Constants';

export interface IWorkersService extends IBaseReduxService {
    loadWorkers: (
        cts: XHRRequestCanceler,
        page?: number,
        count?: number,
        search?: string,
        workerState?: WorkerState | string,
        workerType?: WorkerType | string,
        organization_id?: string
    ) => Promise<WorkersResponse>;

    getWorkerDetails: (
        workerId: string,
        organization_id?: string
    ) => Promise<WorkersDetailsResponse>;

    createWorker: (
        firstName: string,
        lastName: string,
        phoneNumber: string,
        state: WorkerState,
        type: WorkerType,
        activeSitesList: Array<Site>,
        pictureId?: string,
        socialSecurityCode?: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<WorkersDetailsResponse>;

    updateWorker: (
        workerId: string,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        state: WorkerState,
        type: WorkerType,
        activeSitesList: Array<Site>,
        socialSecurityCode?: string,
        pictureId?: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<WorkersDetailsResponse>;

    inviteWorker: (workerId: string, organization_id?: string) => Promise<BaseApiDataResponse>;

    pinWorker: (workerId: string, organization_id?: string) => Promise<BaseApiDataResponse>;

    exportXLS: (auth: string, organization_id?: string) => void;
}

class WorkersService extends BaseReduxService implements IWorkersService {
    private readonly workersDataService: IWorkersDataService;

    constructor() {
        super();
        this.workersDataService = IoC.get<IWorkersDataService>(WorkersDataServiceName);
    }

    loadWorkers = async (
        cts: XHRRequestCanceler,
        page?: number,
        count?: number,
        search?: string,
        workerState?: WorkerState | string,
        workerType?: WorkerType | string,
        organization_id?: string
    ): Promise<WorkersResponse> => {
        try {
            const response = await this.workersDataService.getWorkers(
                page,
                count,
                search,
                workerState,
                workerType,
                cts,
                organization_id
            );
            this.dispatch(WorkersActions.setWorkersInitialized(true));
            this.dispatch(WorkersActions.setWorkers(response.data));
            return Promise.resolve(response);
        } catch (error) {
            this.dispatch(WorkersActions.setWorkersInitialized(true));
            this.dispatch(WorkersActions.setWorkers([]));
            return Promise.reject(error);
        }
    };

    createWorker = async (
        firstName: string,
        lastName: string,
        phoneNumber: string,
        state: WorkerState,
        type: WorkerType,
        activeSitesList: Array<Site>,
        pictureId?: string,
        socialSecurityCode?: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ): Promise<WorkersDetailsResponse> => {
        try {
            const response = await this.workersDataService.createWorker(
                lastName,
                firstName,
                phoneNumber,
                state,
                type,
                WorkerUtils.getIdOfSites(activeSitesList),
                pictureId,
                socialSecurityCode,
                cts,
                organization_id
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    updateWorker = async (
        workerId: string,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        state: WorkerState,
        type: WorkerType,
        activeSitesList: Array<Site>,
        socialSecurityCode?: string,
        pictureId?: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ): Promise<WorkersDetailsResponse> => {
        try {
            const response = await this.workersDataService.updateWorker(
                workerId,
                lastName,
                firstName,
                phoneNumber,
                state,
                type,
                WorkerUtils.getIdOfSites(activeSitesList),
                socialSecurityCode,
                pictureId,
                cts,
                organization_id
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    getWorkerDetails = async (
        workerId: string,
        organization_id?: string
    ): Promise<WorkersDetailsResponse> => {
        try {
            const response = await this.workersDataService.getWorkerDetails(
                workerId,
                undefined,
                organization_id
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    inviteWorker = async (
        workerId: string,
        organization_id?: string
    ): Promise<BaseApiDataResponse> => {
        try {
            const response = await this.workersDataService.inviteWorker(
                workerId,
                undefined,
                organization_id
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    pinWorker = async (
        workerId: string,
        organization_id?: string
    ): Promise<BaseApiDataResponse> => {
        try {
            const response = await this.workersDataService.pinWorker(
                workerId,
                undefined,
                organization_id
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public exportXLS = async (auth: string, organization_id?: string) => {
        ExportUtils.downloadFileFromUrl(
            ExportUtils.getExportUrlWithParams({
                url: Constants.getRoot() + '/api-admin/v1/teamusers/export',
                auth: auth,
                organization_id: organization_id,
            })
        );
    };
}

const WorkersServiceName = 'WorkersService';
export { WorkersService, WorkersServiceName };
