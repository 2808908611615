import {
    IncidentResultDetailsResponse,
    IncidentResultSummaryListResponse,
} from '../../../Models/Incidents/Incident';
import { BaseReduxService, IBaseReduxService } from '../Api/BaseReduxService';
import { IncidentsActions } from './IncidentsActions';
import {
    IIncidentsDataService,
    IncidentsDataServiceName,
} from '../../../DataServices/IncidentDataService';
import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import { IoC } from '../../ServicesContainer';
import { DownloadPhotoProps } from '../Prestations/PrestationsService';

export interface IIncidentsService extends IBaseReduxService {
    loadIncidents: (
        filter_current_user_sites: boolean,
        page: number,
        count: number,
        organizationId?: string,
        cts?: XHRRequestCanceler
    ) => Promise<IncidentResultSummaryListResponse>;
    getIncidentDetails: (
        cts: XHRRequestCanceler,
        incidentId: string,
        organization_id?: string
    ) => Promise<IncidentResultDetailsResponse>;
    updateIncident: (
        cts: XHRRequestCanceler,
        incidentId: string,
        is_solved: boolean,
        organization_id?: string
    ) => Promise<IncidentResultDetailsResponse>;
    setFilterCurrentUserSites: (filter_current_user_sites: boolean) => void;
    downloadPhoto: ({ photoUrl, photoName }: DownloadPhotoProps) => void;
    setPage: (page: number) => void;
}

class IncidentsService extends BaseReduxService {
    private incidentsDataService: IIncidentsDataService;

    constructor() {
        super();
        this.incidentsDataService = IoC.get<IIncidentsDataService>(IncidentsDataServiceName);
    }

    public loadIncidents = async (
        filter_current_user_sites: boolean,
        page: number,
        count: number,
        organizationId?: string,
        cts?: XHRRequestCanceler
    ): Promise<IncidentResultSummaryListResponse> => {
        try {
            const response: IncidentResultSummaryListResponse =
                await this.incidentsDataService.getIncidents(
                    filter_current_user_sites,
                    page,
                    count,
                    organizationId,
                    cts
                );

            this.dispatch(IncidentsActions.setIncidentsInitialized(true));
            this.dispatch(IncidentsActions.setIncidents(response.data));

            return Promise.resolve(response);
        } catch (error) {
            this.dispatch(IncidentsActions.setIncidents([]));
            this.dispatch(IncidentsActions.setIncidentsInitialized(true));
            return Promise.reject(error);
        }
    };

    public getIncidentDetails = async (
        cts: XHRRequestCanceler,
        incidentId: string,
        organization_id?: string
    ): Promise<IncidentResultDetailsResponse> => {
        try {
            const response: IncidentResultDetailsResponse =
                await this.incidentsDataService.getIncidentDetails(
                    cts,
                    incidentId,
                    organization_id
                );

            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public updateIncident = async (
        cts: XHRRequestCanceler,
        incidentId: string,
        is_solved: boolean,
        organization_id?: string
    ): Promise<IncidentResultDetailsResponse> => {
        try {
            const response: IncidentResultDetailsResponse =
                await this.incidentsDataService.updateIncident(
                    cts,
                    incidentId,
                    is_solved,
                    organization_id
                );

            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public setFilterCurrentUserSites = (filter_current_user_sites: boolean) => {
        this.dispatch(
            IncidentsActions.setIncidentsFilterCurrentUserSites(filter_current_user_sites)
        );
    };

    public downloadPhoto = async ({ photoUrl, photoName }: DownloadPhotoProps) => {
        try {
            const response = await this.incidentsDataService.downloadPhoto(photoUrl);
            const buffer = await response.arrayBuffer();
            const link = document.createElement('a');
            const file = new Blob([buffer]);
            link.href = URL.createObjectURL(file);
            link.setAttribute('download', photoName + '.png');
            link.click();
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public setPage = (page: number) => {
        this.dispatch(IncidentsActions.setIncidentsPage(page));
    };
}

const IncidentsServiceName = 'IncidentsService';
export { IncidentsService, IncidentsServiceName };
