import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import {
    AuthDataServiceName,
    IAuthDataService,
} from '../../../DataServices/AuthenticationDataService';
import { BaseApiDataResponse } from '../../../Models/ApiData';
import { AuthenticationResponse } from '../../../Models/Authentication/Login';
import { ApiErrorCodes } from '../../../Models/ErrorCodes';
import { IoC } from '../../ServicesContainer';
import { BaseReduxService } from '../Api/BaseReduxService';
import { AuthenticationActions } from './AuthenticationActions';
import { AuthUtils } from './utils/AuthUtils';

export enum AuthenticateResultType {
    SUCCESS = 'SUCCESS',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    LOGIN_ERROR = 'LOGIN_ERROR',
}

export interface IAuthService extends BaseReduxService {
    setFirstLogin: (first_login: boolean) => void;
    login: (
        email: string,
        password: string,
        cts: XHRRequestCanceler
    ) => Promise<AuthenticateResultType>;
    logout: (reason?: string) => void;
    getAuthorization: () => Promise<string>;
    refreshAuthentication: (forceRefresh?: boolean) => Promise<any>;
}

class AuthService extends BaseReduxService implements IAuthService {
    private authDataService: IAuthDataService;

    constructor() {
        super();
        this.authDataService = IoC.get<IAuthDataService>(AuthDataServiceName);
    }

    public setFirstLogin = async (first_login: boolean) => {
        await this.dispatch(AuthenticationActions.setFirstLogin(first_login));
    };

    public login = async (
        email: string,
        password: string,
        cts: XHRRequestCanceler
    ): Promise<AuthenticateResultType> => {
        try {
            const response: AuthenticationResponse = await this.authDataService.login(
                email,
                password,
                cts
            );
            const transformedAuth = AuthUtils.transformAuth(response.data);
            this.dispatch(AuthenticationActions.loginSuccess(transformedAuth));
            return Promise.resolve(AuthenticateResultType.SUCCESS);
        } catch (error: any) {
            const { error_code }: BaseApiDataResponse = error;
            this.dispatch(AuthenticationActions.loginFail(error));
            if (error_code === ApiErrorCodes.INVALID_CREDENTIALS_MESSAGE) {
                return Promise.resolve(AuthenticateResultType.INVALID_CREDENTIALS);
            } else {
                return Promise.resolve(AuthenticateResultType.LOGIN_ERROR);
            }
        }
    };

    public logout = (reason?: string) => {
        //TODO call logout from api
        //const response = await authenticationDataService.logout();
        return this.dispatch(AuthenticationActions.logout(reason));
    };

    //? @returns {String} the authorization header, NOT the token
    public getAuthorization = (): Promise<string> => {
        const { expiration_date } = this.getState().Authentication;
        if (AuthUtils.authenticationIsExpired(expiration_date)) {
            return this.dispatch(this.refreshAuthentication()).then((auth: any) =>
                AuthUtils.getAuthorizationFromToken(auth.access_token)
            );
        }
        const { access_token } = this.getState().Authentication;
        const auth = AuthUtils.getAuthorizationFromToken(access_token);
        return Promise.resolve(auth);
    };

    /**
     * If the access token is not expired, fires refreshSuccess right away with current state.
     * Resets the initialized state (keeps the authenticated state) and then refreshes the access token
     * @param {Boolean} forceRefresh - If should refresh the tokens, even if the current ones are not expired
     * @returns {Promise<Object>} result - the result of the refresh, same as the login result OR an object
     * with props `isError` and `err`
     * @throws {Promise<Error>} an Axios error if the refresh failed
     */
    refreshAuthentication = async (forceRefresh?: boolean): Promise<any> => {
        const { expiration_date } = this.getState().Authentication;

        const authentication = this.getState().Authentication;
        if (!AuthUtils.authenticationIsExpired(expiration_date) && !forceRefresh) {
            const res = {
                access_token: authentication.access_token,
                refresh_token: authentication.refresh_token,
                expires_in: authentication.expires_in,
                token_type: authentication.token_type,
                expiration_date: authentication.expiration_date,
            };

            this.dispatch(AuthenticationActions.refreshSuccess(res));
            return Promise.resolve(res);
        }

        const { refresh_token } = authentication || { refresh_token: '' };

        if (!refresh_token) {
            const errorMessage = 'No refresh token found, cannot refresh and will logout';

            const error = new Error(errorMessage);
            this.handleApiError(error);
            return this.dispatch(AuthenticationActions.refreshFail(error));
            //.catch((err: any) => ({ isError: true, err }));
        } else {
            this.dispatch(AuthenticationActions.setInitialized(false, 'loading refresh')); // is loading

            try {
                const response: any = await this.authDataService.refresh(refresh_token);
                const transformedAuth = AuthUtils.transformAuth(response.data);
                this.dispatch(AuthenticationActions.refreshSuccess(transformedAuth));
                return Promise.resolve(transformedAuth);
            } catch (error) {
                this.dispatch(AuthenticationActions.refreshFail(error));
                return Promise.reject(error);
            }
        }
    };
}

const AuthServiceName = 'AuthService';
export { AuthService, AuthServiceName };
