import React, { Suspense } from 'react';
import { AppBootstrapper, BootstrapperInitialized } from './AppBootstrapper';
import { AppRouter } from './Web/Components/Templates/AppRouter/AppRouter';
import { LoadingPage } from './Web/Components/Templates/Loading/LoadingPage/LoadingPage';
import { AppWrapper } from './Web/Components/Templates/Wrappers/AppWrapper/AppWrapper';
import { StateLoadingGate } from './Web/Components/Templates/Wrappers/StateLoadingGate/StateLoadingGate';
import { OnBoardingHolder } from './Web/Components/Views/OnBoarding/OnBoardingHolder';

const boot: AppBootstrapper = new AppBootstrapper();
const {
    store,
    routes,
    persistor,
    defaultRoote,
    mountActions,
}: BootstrapperInitialized = boot.initialize();

const Application: React.FunctionComponent = () => (
    <AppWrapper
        setupIntl
        store={store}
        persistor={persistor}
        withEssentialReload
        mountActions={mountActions}
    >
        <StateLoadingGate loadableStateNames={['Authentication']}>
            <Suspense fallback={<LoadingPage />}>
                <AppRouter routes={routes} defaultRoote={defaultRoote} />
                <OnBoardingHolder />
            </Suspense>
        </StateLoadingGate>
    </AppWrapper>
);

export { Application };
