import Axios, { AxiosInstance, AxiosResponse } from 'axios';

import { ApiClientOptions } from './ApiClientOptions';
import { IErrorHandler } from './ErrorHandler';
import { createErrorInterceptor, createResponseInterceptor } from './XHRInterceptors';
import {
    defaultSerializeRequestParameters,
    getAxiosRequestConfig,
    getAxiosRequestUploadConfig,
    XHRRequestConfig,
} from './XHRRequest';
import { XHRRequestCanceler } from './XHRRequestCanceler';

export enum UploadDataType {
    //Files = "files",
    File = 'file',
    Image = 'image',
    Video = 'video',
}

export interface UploadData {
    type: UploadDataType;
    value: File;
    //* value doit être un Array<File>
}

export class ApiClient {
    private axiosClient: AxiosInstance;
    private errorHandler?: IErrorHandler;

    //* Initialize the ApiClient.
    constructor(options: ApiClientOptions) {
        const { baseUrl, paramsSerializer, errorHandler } = options;

        this.errorHandler = errorHandler;

        //* The ApiClient wraps calls to the underlying Axios client.
        this.axiosClient = Axios.create({
            baseURL: baseUrl,
            timeout: 1000 * 60,
            headers: { 'X-Initialized-At': Date.now().toString() },
            paramsSerializer: paramsSerializer || defaultSerializeRequestParameters,
        });

        if (errorHandler) {
            this.initializeInterceptors();
        }
    }

    private initializeInterceptors = () => {
        this.applyInterceptors(
            (error: any) => {
                if (!Axios.isCancel(error) && this.errorHandler) {
                    this.errorHandler?.handle(error);
                    //*DO NOTHING if NOT handled #REALY 😎
                }
            },
            (sucess: any) => {
                //*DO NOTHING  #REALY 🙂
            }
        );
    };

    /**
     * Applies response and error interceptors to XHR. These interceptors
     * will use the callback functions given, according to the result of the request
     * @param {Function} handleError - Called with keys `config` and `response`. Reponse may be undefined of nothing was returned
     * @param {Function} handleSuccess - Called with response data as first param
     */
    private applyInterceptors = (handleError: any, handleSuccess?: any) => {
        this.axiosClient.interceptors.response.use(
            createResponseInterceptor(handleError, handleSuccess),
            createErrorInterceptor(handleError)
        );
    };

    public setAuthorization = (authorization: string) => {
        this.axiosClient.defaults.headers.common.Authorization = authorization;
    };

    //#region REQUEST

    public async get<TResult>(
        url: string,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        try {
            const axiosRequestConfig = getAxiosRequestConfig(cts, config);
            const axiosResponse: AxiosResponse<TResult> = await this.axiosClient.get<TResult>(
                url,
                axiosRequestConfig
            );
            cts?.setCanceler(null);
            const response: any = { ...axiosResponse };
            return Promise.resolve<TResult>(response);
        } catch (errorResponse) {
            return this.onRequestFailed<TResult>(errorResponse, cts, 'ApiClient get failed');
        }
    }

    public async post<TResult>(
        url: string,
        body: object,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        try {
            const axiosRequestConfig = getAxiosRequestConfig(cts, config);
            const axiosResponse: AxiosResponse<TResult> = await this.axiosClient.post<TResult>(
                url,
                body,
                axiosRequestConfig
            );
            cts?.setCanceler(null);
            const response: any = { ...axiosResponse };
            return Promise.resolve<TResult>(response);
        } catch (errorResponse) {
            return this.onRequestFailed<TResult>(errorResponse, cts, 'ApiClient post failed');
        }
    }

    public async put<TResult>(
        url: string,
        body: object,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        try {
            const axiosRequestConfig = getAxiosRequestConfig(cts, config);
            const axiosResponse: AxiosResponse<TResult> = await this.axiosClient.put<TResult>(
                url,
                body,
                axiosRequestConfig
            );
            cts?.setCanceler(null);
            const response: any = { ...axiosResponse };
            return Promise.resolve<TResult>(response);
        } catch (errorResponse) {
            return this.onRequestFailed<TResult>(errorResponse, cts, 'ApiClient put failed');
        }
    }

    public async patch<TResult>(
        url: string,
        body: object,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        try {
            const axiosRequestConfig = getAxiosRequestConfig(cts, config);
            const axiosResponse: AxiosResponse<TResult> = await this.axiosClient.patch<TResult>(
                url,
                body,
                axiosRequestConfig
            );
            cts?.setCanceler(null);
            const response: any = { ...axiosResponse };
            return Promise.resolve<TResult>(response);
        } catch (errorResponse) {
            return this.onRequestFailed<TResult>(errorResponse, cts, 'ApiClient patch failed');
        }
    }

    public async upload<TResult>(
        url: string,
        uploadData: UploadData,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig,
        onProgress?: (value: number) => void
    ): Promise<TResult> {
        try {
            const data = new FormData();
            data.append(uploadData.type, uploadData.value);

            const axiosRequestConfig = getAxiosRequestUploadConfig(cts, config, onProgress);
            const axiosResponse: AxiosResponse<TResult> = await this.axiosClient.post<TResult>(
                url,
                data,
                axiosRequestConfig
            );
            cts?.setCanceler(null);
            const response: any = { ...axiosResponse };
            return Promise.resolve<TResult>(response);
        } catch (errorResponse) {
            return this.onRequestFailed<TResult>(errorResponse, cts, 'ApiClient upload failed');
        }
    }

    public async delete<TResult>(
        url: string,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        try {
            const axiosRequestConfig = getAxiosRequestConfig(cts, config);
            const axiosResponse: AxiosResponse<TResult> = await this.axiosClient.delete<TResult>(
                url,
                axiosRequestConfig
            );
            cts?.setCanceler(null);
            const response: any = { ...axiosResponse };
            return Promise.resolve<TResult>(response);
        } catch (errorResponse) {
            return this.onRequestFailed<TResult>(errorResponse, cts, 'ApiClient delete failed');
        }
    }

    private onRequestFailed = <TResult>(
        errorResponse: any,
        cts?: XHRRequestCanceler,
        message?: string
    ): Promise<TResult> => {
        cts?.setCanceler(null);
        const data = errorResponse?.response?.data;
        const result: TResult = { ...data };
        return Promise.reject(result);
    };

    //#endregion
}
