export class Constants {
    static CLIENT_ID = 'BACK_WEB';
    static CLIENT_SECRET = 'back_web';
    static API_BASE_URL = '__API_HOST__';
    static API_BASE_URL_DEV = 'https://ticktime-api-dev.azurewebsites.net';
    //static API_BASE_URL_DEV = 'http://localhost:65464';

    static DEFAULT_ITEM_COUNT_PER_PAGE = 15;

    static GOOGLE_MAP_URL =
        'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places';
    static GOOGLE_MAP_API_KEY_DEV = 'AIzaSyDa6oEFzlKlMuzC5MWLhQiGdBgQc8XCQms';
    static GOOGLE_MAP_API_KEY = '__GOOGLE_MAP_API_KEY__';
    static DEFAULT_MAP_ZOOM = 16;
    static DEFAULT_MAP_COORD = { lat: 50.631518, lng: 3.060877 };
    static DEFAULT_MAX_WIDTH = 1024;

    static CLIENT = {
        client_id: Constants.CLIENT_ID,
        client_secret: Constants.CLIENT_SECRET,
    };
    static isDev = process.env.NODE_ENV === 'development';

    static getRoot = () => (Constants.isDev ? Constants.API_BASE_URL_DEV : Constants.API_BASE_URL);

    static getGoogleMapKey = () =>
        Constants.isDev ? Constants.GOOGLE_MAP_API_KEY_DEV : Constants.GOOGLE_MAP_API_KEY;
}
