import React from 'react';

interface OnBoardingProviderProps {
    children: React.ReactNode;
}

export const OnBoardingContext = React.createContext({
    play: false,
    setPlay: (b: boolean) => {},
    skipFirstPage: false,
    setSkipFirstPage: (b: boolean) => {},
});

export const OnBoardingProvider: React.FC<OnBoardingProviderProps> = ({ children }) => {
    const [play, setPlay] = React.useState(false);
    const [skipFirstPage, setSkipFirstPage] = React.useState(false);
    const state = React.useMemo(() => {
        return {
            play,
            setPlay,
            skipFirstPage,
            setSkipFirstPage,
        };
    }, [play, skipFirstPage]);

    return <OnBoardingContext.Provider value={state}>{children}</OnBoardingContext.Provider>;
};
