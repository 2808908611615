import { Prestation } from '../../../models/Prestations/Prestations';
import {
    PrestationsActionsTypes,
    PrestationsActionSetPrestationsList,
} from './typings/PrestationsActionsTypes';

export class PrestationsActions {
    static SetPrestationsInitialized = (initialized: boolean) => ({
        type: PrestationsActionsTypes.SET_PRESTATIONS_INITIALIZED,
        payload: { initialized },
    });

    static SetPrestationsList = (
        prestations: Array<Prestation>
    ): PrestationsActionSetPrestationsList => ({
        type: PrestationsActionsTypes.SET_PRESTATIONS_LIST,
        payload: { prestations },
    });

    static SetPrestationAnomaly = (anomaly: boolean) => ({
        type: PrestationsActionsTypes.SET_PRESTATIONS_ANOMALY,
        payload: { anomaly },
    });

    static SetPrestationFilterCurrentUserSites = (filter_current_user_sites: boolean) => ({
        type: PrestationsActionsTypes.SET_PRESTATIONS_FILTER_CURRENT_USER_SITES,
        payload: { filter_current_user_sites },
    });

    static SetPrestationComment = (comment?: boolean) => ({
        type: PrestationsActionsTypes.SET_PRESTATIONS_COMMENT,
        payload: { comment },
    });

    static SetPrestationClockingAnomaly = (clocking_anomaly?: boolean) => ({
        type: PrestationsActionsTypes.SET_PRESTATIONS_CLOCKING_ANOMALY,
        payload: { clocking_anomaly },
    });

    static SetPrestationStartDate = (startDate: Date) => ({
        type: PrestationsActionsTypes.SET_PRESTATIONS_START_DATE,
        payload: { startDate },
    });

    static SetPrestationEndDate = (endDate: Date) => ({
        type: PrestationsActionsTypes.SET_PRESTATIONS_END_DATE,
        payload: { endDate },
    });

    static SetPrestationSearch = (search: string) => ({
        type: PrestationsActionsTypes.SET_PRESTATIONS_SEARCH,
        payload: { search },
    });

    static SetPrestationPage = (page: number) => ({
        type: PrestationsActionsTypes.SET_PRESTATIONS_PAGE,
        payload: { page },
    });
}
