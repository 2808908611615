import { EmailActionType } from '../Services/Data/Authentication/typings/EmailActionTypes';
import { MeActions } from '../Services/Data/Users/Me/MeActions';
import { IMeService, MeServiceName } from '../Services/Data/Users/Me/MeService';
import { IoC } from '../Services/ServicesContainer';


export const MeMiddleware = ({ dispatch }: any) => (next: any) => (action: any) => {
    if (action) {
        switch (action.type) {
            case EmailActionType.LOGIN_EMAIL_SUCCESS:
            case EmailActionType.REFRESH_EMAIL_SUCCESS:
                const meService = IoC.get<IMeService>(MeServiceName);
                meService.getMyInfo().catch((err: any) => err);
                break;
            case EmailActionType.LOGIN_EMAIL_FAIL:
            case EmailActionType.REFRESH_EMAIL_FAIL:
                dispatch(MeActions.setInitialized(true));
                break;
            default:
                break;
        }
    }
    return next(action);
};
