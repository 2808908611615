import { Site } from '../../../models/Sites/Sites';
import { SitesActionSetSitesList, SitesActionsTypes } from './typings/SitesActionsTypes';

export class SitesActions {
    static SetSitesInitialized = (initialized: boolean) => ({
        type: SitesActionsTypes.SET_SITES_INITIALIZED,
        payload: { initialized },
    });

    static SetSitesList = (sites: Array<Site>): SitesActionSetSitesList => ({
        type: SitesActionsTypes.SET_SITES_LIST,
        payload: { sites },
    });

    static SetSitesState = (state: string) => ({
        type: SitesActionsTypes.SET_SITES_STATE,
        payload: { state },
    });

    static SetSitesFilterCurrentUserSites = (filter_current_user_sites: boolean) => ({
        type: SitesActionsTypes.SET_SITES_FILTER_CURRENT_USER_SITES,
        payload: { filter_current_user_sites },
    });

    static SetSitesSearch = (search: string) => ({
        type: SitesActionsTypes.SET_SITES_SEARCH,
        payload: { search },
    });

    static SetSitesPage = (page: number) => ({
        type: SitesActionsTypes.SET_SITES_PAGE,
        payload: { page },
    });

    static SetClients = (clients: Array<string>) => ({
        type: SitesActionsTypes.SET_CLIENTS,
        payload: { clients },
    });
}
