import { connectRouter, routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const RouterReducer = connectRouter(history);

const RouterMiddleware = createRouterMiddleware(history);

export { history, RouterReducer, RouterMiddleware };
