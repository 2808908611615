export interface IErrorHandler {
    handle: (exception: any, customErrorHandler?: (exception: any) => boolean) => boolean;
}

class ErrorHandler implements IErrorHandler {
    ERROR_HANDLED: boolean = true;
    ERROR_NOT_HANDLED: boolean = false;

    handle = (error: any, customErrorHandler?: (error: any) => boolean): boolean => {
        return this.ERROR_HANDLED;
    };
}
const ErrorHandlerName = 'ErrorHandler';

export { ErrorHandler, ErrorHandlerName };
