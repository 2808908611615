import { LoadablePrestationList } from '../../Pages/Prestations/PrestationList/LoadablePrestationList';
import { LoadablePrestationCreate } from '../../Pages/Prestations/PrestationCreate/LoadablePrestationCreate';
import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../../Components/Templates/AppRouter/PrivateRoute';
import { LoadablePrestationDetails } from '../../Pages/Prestations/PrestationDetails/LoadablePrestationDetails';
import { AppRoutes } from '../../../Core/Routes/Routes';

const getPrestationListRoute = (props: object): AppRouteType => ({
    id: AppRoutes.PRESTATION_LIST,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.PRESTATION_LIST,
        component: LoadablePrestationList,
        ...props,
    },
});

const getPrestationCreateRoute = (props: object): AppRouteType => ({
    id: AppRoutes.PRESTATION_CREATE,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.PRESTATION_CREATE,
        component: LoadablePrestationCreate,
        ...props,
    },
});

const getPrestationDetailsRoute = (props: object): AppRouteType => ({
    id: AppRoutes.PRESTATION_DETAILS,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.PRESTATION_DETAILS,
        component: LoadablePrestationDetails,
        ...props,
    },
});

export const getPrestationRoutes = (args: object) => [
    getPrestationListRoute(args),
    getPrestationCreateRoute(args),
    getPrestationDetailsRoute(args),
];
