import { store } from '../../../../../Configs/store/store';
import { XHRRequestCanceler } from '../../../../../Libs/xhr/XHRRequestCanceler';
import { IUserDataService, UserDataServiceName } from '../../../../DataServices/UserDataService';
import { BackOfficeUserRole, MeResponse } from '../../../../Models/Users/Users';
import { IoC } from '../../../ServicesContainer';
import { BaseReduxService } from '../../Api/BaseReduxService';
import { OrganizationsActions } from '../../Organizations/OrganizationsActions';
import {
    IOrganizationService,
    OrganizationsServiceName,
} from '../../Organizations/OrganizationsService';
import { MeActions } from './MeActions';

export interface IMeService {
    getMyInfo: (cts?: XHRRequestCanceler) => Promise<MeResponse | any>;
    changeOrganization: (organization_id: string) => void;
}

class MeService extends BaseReduxService implements IMeService {
    private userDataService: IUserDataService;
    private organizationService: IOrganizationService;
    constructor() {
        super();
        this.organizationService = IoC.get<IOrganizationService>(OrganizationsServiceName);
        this.userDataService = IoC.get<IUserDataService>(UserDataServiceName);
    }

    public updateAbilityPermissions =
        (extraRules?: any, updaterName?: any) => (dispatch: any, getState: any) => {
            // const {
            //     Me: { ability: { rules = [] } = { rules: [] } },
            // } = getState();
            // const nextAbility = createAbility([...rules, ...extraRules]);
            // dispatch(MeActions.setAbility(nextAbility, updaterName));
        };

    public changeOrganization = (organization_id: string) => {
        const info = this.getState().Me.data;
        if (info) {
            this.dispatch(MeActions.setInfo({ ...info, organization_id }));
            this.dispatch(OrganizationsActions.SetLastUsedOrganizationId(organization_id));
        }
    };

    public getMyInfo = async (cts?: XHRRequestCanceler): Promise<MeResponse | any> => {
        this.dispatch(MeActions.setInitialized(false));
        const { last_used_organization_id } = store.getState().Organization;

        try {
            const response: MeResponse = await this.userDataService.getMyInfo(cts);

            this.dispatch(MeActions.setInfo(response.data));
            // dispatch(MeActions.setAbility(createAbility(getMeRules(data)), 'init_me'));
            this.dispatch(MeActions.setInitialized(true));

            if (response.data.role === BackOfficeUserRole.SuperAdmin) {
                if (last_used_organization_id) {
                    this.changeOrganization(last_used_organization_id);
                } else {
                    const response = await this.organizationService.getOrganizations(
                        new XHRRequestCanceler(),
                        0,
                        10
                    );
                    if (response.data.length > 0) {
                        this.changeOrganization(response.data[0].id);
                    }
                }
            }

            return Promise.resolve(response);
        } catch (error) {
            this.dispatch(MeActions.setInfo());
            //dispatch(MeActions.setAbility(createAbility(getMeRules()), 'init_no_me'));
            this.dispatch(MeActions.setInitialized(true));
            return Promise.reject(error);
        }
    };
}

const MeServiceName = 'MeService';
export { MeService, MeServiceName };
