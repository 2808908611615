import { UploadData } from '../../../Libs/xhr/ApiClient';
import { XHRRequestConfig } from '../../../Libs/xhr/XHRRequest';
import { XHRRequestCanceler } from '../../../Libs/xhr/XHRRequestCanceler';
import { BaseApiDataResponse } from '../../Models/ApiData';
import { xhrClient } from '../../Services/Data/Api/ApiService';

export class BaseDataService {
    get<TResult extends BaseApiDataResponse>(
        url: string,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        return xhrClient.get<TResult>(url, cts, config);
    }

    post<TResult extends BaseApiDataResponse>(
        url: string,
        body: object,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        return xhrClient.post<TResult>(url, body, cts, config);
    }

    put<TResult extends BaseApiDataResponse>(
        url: string,
        body: object,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        return xhrClient.put<TResult>(url, body, cts, config);
    }

    patch<TResult extends BaseApiDataResponse>(
        url: string,
        body: object,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        return xhrClient.patch<TResult>(url, body, cts, config);
    }

    async upload<TResult extends BaseApiDataResponse>(
        url: string,
        uploadData: UploadData,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig,
        onProgress?: (value: number) => void
    ): Promise<TResult> {
        return xhrClient.upload<TResult>(url, uploadData, cts, config, onProgress);
    }

    delete<TResult extends BaseApiDataResponse>(
        url: string,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        return xhrClient.delete<TResult>(url, cts, config);
    }
}
