import React from 'react';
import { LoadingPage } from '../../Loading/LoadingPage/LoadingPage';

export interface LoadingGateProps {
    children?: React.ReactNode | React.ReactNode[];
    loadingNode?: React.ReactNode;
    minDelay?: number;
    /** Shows the loading page */
    showLoader: boolean;
}

/**
 * Just a simple gate that either show LoadingPage or children, according to its 'showLoader' prop.
 * You can then use reselect and compose multiple selectors to calculate the final 'showLoader' value
 */
const LoadingGate: React.FunctionComponent<LoadingGateProps> = ({
    children,
    loadingNode = null,
    minDelay = 2 * 1000,
    showLoader,
}: LoadingGateProps) => {
    const loadingTimeout = React.useRef<NodeJS.Timeout>();
    const [passedMinDelay, setPassedMinDelay] = React.useState(minDelay === 0);

    /* eslint-disable react-hooks/exhaustive-deps */
    // we only want to run this on mount
    React.useEffect((): (() => void) => {
        if (minDelay !== 0) {
            loadingTimeout.current = setTimeout((): void => {
                setPassedMinDelay(true);
            }, minDelay);
        }

        return (): void => {
            if (loadingTimeout.current !== undefined) {
                clearTimeout(loadingTimeout.current);
                loadingTimeout.current = undefined;
            }
        };
    }, []);

    if (showLoader || !passedMinDelay) {
        return loadingNode ? <>{loadingNode}</> : <LoadingPage />;
    }

    return <>{children}</>;
};

export { LoadingGate };
