import { UploadMediaResponse } from '../Models/Medias/Medias';
import { BaseDataService } from './Base/BaseDataService';
import { UploadData, UploadDataType } from '../../Libs/xhr/ApiClient';

const MediaEndpoints = {
    UPLOAD_PICTURE: '/api-admin/v1/pictures/',
};

export interface IMediaDataService {
    uploadPicture: (picture: File) => Promise<UploadMediaResponse>;
}

class MediaDataService extends BaseDataService implements IMediaDataService {
    uploadPicture = async (picture: File): Promise<UploadMediaResponse> => {
        const dataToUpload: UploadData = {
            type: UploadDataType.Image,
            value: picture,
        };
        return this.upload<UploadMediaResponse>(MediaEndpoints.UPLOAD_PICTURE, dataToUpload);
    };
}

const MediaDataServiceName = 'MediaDataService';

export { MediaDataService, MediaDataServiceName };
