import {
    BackOfficeUsersActionType,
    SetBackOfficeUsersAction,
    SetBackOfficeUsersInitializedAction,
    SetBackOfficeUsersPageAction,
} from './typings/BackOfficeUsersActionTypes';
import { User } from '../../../../Models/Users/Users';

export class BackOfficeUsersActions {
    static setBackofficeUsersInitialized = (
        initialized: boolean
    ): SetBackOfficeUsersInitializedAction => ({
        type: BackOfficeUsersActionType.SET_USERS_INITIALIZED,
        payload: { initialized },
    });

    static setBackofficeUsers = (items: Array<User>): SetBackOfficeUsersAction => ({
        type: BackOfficeUsersActionType.SET_USERS,
        payload: { items },
    });

    static setBackOfficeUsersPage = (page: number): SetBackOfficeUsersPageAction => ({
        type: BackOfficeUsersActionType.SET_PAGE,
        payload: { page },
    });
}
