import { User } from '../../../../../Models/Users/Users';

export enum BackOfficeUsersActionType {
    SET_USERS_INITIALIZED = 'app/backOfficeUsers/set/initialized',
    SET_USERS = 'app/backOfficeUsers/set/users',
    SET_PAGE = 'app/backOfficeUsers/set/page',
}

export interface SetBackOfficeUsersInitializedAction {
    type: typeof BackOfficeUsersActionType.SET_USERS_INITIALIZED;
    payload: { initialized: boolean };
}

export interface SetBackOfficeUsersAction {
    type: typeof BackOfficeUsersActionType.SET_USERS;
    payload: { items: Array<User> };
}

export interface SetBackOfficeUsersPageAction {
    type: typeof BackOfficeUsersActionType.SET_PAGE;
    payload: { page: number };
}

export type BackOfficeUsersAction =
    | SetBackOfficeUsersInitializedAction
    | SetBackOfficeUsersAction
    | SetBackOfficeUsersPageAction;
