import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../Configs/store/AppReducer';
import { useLoggedUser } from '../../../Components/Views/AuthenticatedPageLayout/useLoggedUser';
import { OnBoardingContext } from '../../Templates/Wrappers/AppWrapper/OnBoardingProvider';
import { useAuthenticatedUser } from '../AuthenticatedPageLayout/useAuthenticatedUser';

const OnBoarding = React.lazy(() => import('./OnBoarding'));

export const OnBoardingHolder = ({}) => {
    const { play, setPlay, skipFirstPage, setSkipFirstPage } = React.useContext(OnBoardingContext);
    const first_login = useLoggedUser();
    const { accepted_cgu, initialized } = useAuthenticatedUser();
    const { isAuthenticated } = useSelector((state: AppState) => state.Authentication);

    if (isAuthenticated && (first_login || (!accepted_cgu && initialized))) {
        setSkipFirstPage(false);
        setPlay(true);
    }
    return play ? <OnBoarding skipFirstPage={skipFirstPage} /> : null;
};
