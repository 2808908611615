import { Worker } from '../../../../Models/Workers/Workers';

export enum WorkersActionsTypes {
    SET_WORKERS_INITIALIZED = 'app/workers/set/initialized',
    SET_WORKERS = 'app/workers/set/workers',
    SET_WORKERS_LIST_PAGE = 'app/workers/set/list/page',
    SET_WORKERS_SEARCH = 'app/workers/set/search',
    SET_WORKERS_STATE = 'app/workers/set/state',
    SET_WORKERS_TYPE = 'app/workers/set/type',
}

export interface WorkersActionSetInitialized {
    type: WorkersActionsTypes.SET_WORKERS_INITIALIZED;
    payload: { initialized: boolean };
}

export interface WorkersActionSetWorkers {
    type: WorkersActionsTypes.SET_WORKERS;
    payload: { items: Array<Worker> };
}

export interface WorkersActionSetListPage {
    type: WorkersActionsTypes.SET_WORKERS_LIST_PAGE;
    payload: { page: number };
}

export interface WorkersActionSetSearch {
    type: WorkersActionsTypes.SET_WORKERS_SEARCH;
    payload: { search: string };
}

export interface WorkersActionSetState {
    type: WorkersActionsTypes.SET_WORKERS_STATE;
    payload: { state: string };
}

export interface WorkersActionSetType {
    type: WorkersActionsTypes.SET_WORKERS_TYPE;
    payload: { type: string };
}

export type WorkersActions =
    | WorkersActionSetInitialized
    | WorkersActionSetWorkers
    | WorkersActionSetListPage
    | WorkersActionSetSearch
    | WorkersActionSetState
    | WorkersActionSetType;
