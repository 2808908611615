import {
    WorkersActionsTypes,
    WorkersActionSetInitialized,
    WorkersActionSetWorkers,
    WorkersActionSetListPage,
    WorkersActionSetSearch,
    WorkersActionSetState,
    WorkersActionSetType,
} from './typings/WorkersActionsTypes';
import { Worker, WorkerState } from '../../../models/Workers/Workers';

export class WorkersActions {
    static setWorkersInitialized = (initialized: boolean): WorkersActionSetInitialized => ({
        type: WorkersActionsTypes.SET_WORKERS_INITIALIZED,
        payload: { initialized },
    });

    static setWorkers = (items: Array<Worker>): WorkersActionSetWorkers => ({
        type: WorkersActionsTypes.SET_WORKERS,
        payload: { items },
    });

    static setListPage = (page: number): WorkersActionSetListPage => ({
        type: WorkersActionsTypes.SET_WORKERS_LIST_PAGE,
        payload: { page },
    });

    static setSearch = (search: string): WorkersActionSetSearch => ({
        type: WorkersActionsTypes.SET_WORKERS_SEARCH,
        payload: { search },
    });

    static setState = (state: WorkerState | string): WorkersActionSetState => ({
        type: WorkersActionsTypes.SET_WORKERS_STATE,
        payload: { state },
    });

    static setType = (type: WorkerType | string): WorkersActionSetType => ({
        type: WorkersActionsTypes.SET_WORKERS_TYPE,
        payload: { type },
    });
}
