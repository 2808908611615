import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../../Components/Templates/AppRouter/PrivateRoute';
import { LoadableSiteCreate } from '../../Pages/Sites/SiteCreate/LoadableSiteCreate';
import { LoadableSiteDetails } from '../../Pages/Sites/SiteDetails/LoadableSiteDetails';
import { LoadableSiteList } from '../../Pages/Sites/SiteList/LoadableSiteList';
import { AppRoutes } from '../../../Core/Routes/Routes';

const getSiteListRoute = (props: object): AppRouteType => ({
    id: AppRoutes.SITE_LIST,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.SITE_LIST,
        component: LoadableSiteList,
        ...props,
    },
});

const getSiteCreateRoute = (props: object): AppRouteType => ({
    id: AppRoutes.SITE_CREATE,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.SITE_CREATE,
        component: LoadableSiteCreate,
        ...props,
    },
});

const getSiteDetailsRoute = (props: object): AppRouteType => ({
    id: AppRoutes.SITE_DETAILS,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.SITE_DETAILS,
        component: LoadableSiteDetails,
        ...props,
    },
});

export const getSiteRoutes = (args: object) => [
    getSiteListRoute(args),
    getSiteCreateRoute(args),
    getSiteDetailsRoute(args),
];
