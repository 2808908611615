import { IoC } from '../ServicesContainer';

export interface INavigationService {
    goTo: (route: string) => void;
    goToHome: () => void;

    //* AUTHENTICATION
    goToLogin: () => void;
    goToForgotPassword: () => void;

    //* WORKERS
    goToWorkerList: () => void;
    goToWorkerCreate: () => void;
    goToWorkerDetails: (id: string) => void;
    getWorkerDetails: (id: string) => string;

    //* PRESTATIONS
    goToPrestationList: () => void;
    goToPrestationCreate: () => void;
    goToPrestationDetails: (id: string) => void;
    getPrestationDetails: (id: string) => string;

    //* SITES
    goToSiteList: () => void;
    goToSiteCreate: () => void;
    goToSiteDetails: (id: string) => void;
    getSiteDetails: (id: string) => string;

    //* USERS
    goToUserList: () => void;
    goToUserCreate: () => void;
    goToUserDetails: (id: string) => void;
    getUserDetails: (id: string) => string;

    //* INCIDENTS
    goToIncidentList: () => void;
    getIncidentDetails: (id: string) => string;

    //* MESSAGES
    goToMessageList: () => void;
    goToMessageCreate: () => void;
    goToMessageDetails: (id: string) => void;
    getMessageDetails: (id: string) => string;

    //* Utils
    goToCgu: () => void;
    goToInfo: () => Window | null;
    
    getPreviousRoute: () => string;
}

const NavigationServiceName = 'NavigationService';
const getNavigationService = () => IoC.get<INavigationService>(NavigationServiceName);

export { NavigationServiceName, getNavigationService };
