export enum AppRoutes {
    //* AuthRoutes
    LOGIN = '/login',
    PASSWORD_CREATE = '/passwords/initialize/:email/:id',
    PASSWORD_FORGOT = '/passwords/forgot',
    PASSWORD_RESET = '/passwords/reset/:email/:id',

    //* PrestationsRoutes
    PRESTATION_LIST = '/prestations',
    PRESTATION_CREATE = '/prestations/create',
    PRESTATION_DETAILS = '/prestations/:id',

    //* SitesRoutes
    SITE_LIST = '/sites',
    SITE_CREATE = '/sites/create',
    SITE_DETAILS = '/sites/:id',

    //* UsersRoutes
    USER_LIST = '/users',
    USER_CREATE = '/users/create',
    USER_DETAILS = '/users/:id',

    //* WorkersRoutes
    WORKER_LIST = '/workers',
    WORKER_CREATE = '/workers/create',
    WORKER_DETAILS = '/workers/:id',

    //* UtilsRoutes
    CGU = '/cgu',

    //* IncidentsRoutes
    INCIDENT_LIST = '/incidents',
    INCIDENT_DETAILS = '/incidents/:id',

    //* MessagesRoutes
    MESSAGE_LIST = '/messages',
    MESSAGE_CREATE = '/messages/create',
    MESSAGE_DETAILS = '/messages/:id',
}
