import * as OrganizationsActionsTypes from './typings/OrganizationsActionsTypes';

interface OrganizationsState {
    last_used_organization_id?: string;
}

const initialState: OrganizationsState = {
    last_used_organization_id: undefined,
};

export const OrganizationsReducer = (
    state = initialState,
    action: OrganizationsActionsTypes.OrganizationsActions
): OrganizationsState => {
    switch (action.type) {
        case OrganizationsActionsTypes.OrganizationsActionsTypes.SET_LAST_USED_ORGANIZATION_ID:
            return {
                ...state,
                last_used_organization_id: action.payload.last_used_organization_id,
            };
        default:
            return state;
    }
};
