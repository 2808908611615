import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast, ToastId } from 'react-toastify';

import { IToastService } from '../../Core/Services/Contracts/IToastService';
import { CustomToast, CustomToastType } from '../Components/Views/CustomToast/CustomToast';

class ToastService implements IToastService {
    public showSuccess(content: string): ToastId {
        return toast.success(CustomToast({ type: CustomToastType.SUCCESS, content }));
    }
    public showError(content: string): ToastId {
        return toast.error(CustomToast({ type: CustomToastType.ERROR, content }));
    }

    public showInfo(content: React.ReactNode): ToastId {
        return toast.info(CustomToast({ type: CustomToastType.INFO, content }));
    }

    public showSuccessUpdate(): ToastId {
        return toast.success(
            CustomToast({
                type: CustomToastType.SUCCESS,
                content: <FormattedMessage id="Toast_GenericSuccessUpdateData" />,
            })
        );
    }

    public notImplemented(): ToastId {
        return toast.info(
            CustomToast({ type: CustomToastType.INFO, content: <div>Not implemented yet</div> })
        );
    }
}

export { ToastService };
