import { defineAbility } from '@casl/ability';
import { Ability } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { BoundAbilityCreator } from '../../../Core/Types/Ability/BoundAbility';

export const AbilityContext = React.createContext(defineAbility((): void => {}));

// eslint-disable-next-line react/prop-types
export const AbilityProvider: React.FunctionComponent = ({ children }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ability = useSelector((state: any): Ability => state.Me.ability);

    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export const useAbilityContext = (): Ability => React.useContext(AbilityContext);

export const useBoundAbilityFromContext = <BoundAbilityType extends Ability>(
    creator: BoundAbilityCreator<BoundAbilityType>
): BoundAbilityType => {
    const ability = useAbilityContext();

    return creator(ability);
};

export const useAbilityFromStore = (): Ability =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useSelector((state: any): Ability => state.Me.ability);

export const Can = createContextualCan(AbilityContext.Consumer);
