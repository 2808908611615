import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../../Components/Templates/AppRouter/PrivateRoute';
import { LoadableWorkerCreate } from '../../Pages/Workers/WorkerCreate/LoadableWorkerCreate';
import { LoadableWorkerDetails } from '../../Pages/Workers/WorkerDetails/LoadableWorkerDetails';
import { LoadableWorkerList } from '../../Pages/Workers/WorkerList/LoadableWorkerList';
import { AppRoutes } from '../../../Core/Routes/Routes';

const getWorkerListRoute = (props: object): AppRouteType => ({
    id: AppRoutes.WORKER_LIST,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.WORKER_LIST,
        component: LoadableWorkerList,
        ...props,
    },
});

const getWorkerCreateRoute = (props: object): AppRouteType => ({
    id: AppRoutes.WORKER_CREATE,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.WORKER_CREATE,
        component: LoadableWorkerCreate,
        ...props,
    },
});

const getWorkerDetailsRoute = (props: object): AppRouteType => ({
    id: AppRoutes.WORKER_DETAILS,
    render: PrivateRoute,
    props: {
        exact: true,
        path: AppRoutes.WORKER_DETAILS,
        component: LoadableWorkerDetails,
        ...props,
    },
});

export const getWorkerRoutes = (args: object) => [
    getWorkerListRoute(args),
    getWorkerCreateRoute(args),
    getWorkerDetailsRoute(args),
];
